import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * YouTube icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const YouTube: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 27 22" {...remainingProps}>
      <title>Subscribe on YouTube</title>
      <g fillRule="nonzero" fill={colorString}>
        <path
          d="M26.551 4.538s-.26-2.02-1.06-2.91C24.478.462 23.342.455 22.822.388 19.087.092 13.49.092 13.49.092h-.013S7.881.09 4.15.389c-.52.066-1.656.073-2.671 1.239-.799.89-1.06 2.91-1.06 2.91s-.266 2.37-.266 4.74v2.223c0 2.372.266 4.742.266 4.742s.26 2.019 1.06 2.907c1.015 1.166 2.346 1.13 2.94 1.252 2.133.225 9.067.295 9.067.295s5.603-.01 9.335-.305c.521-.069 1.657-.075 2.672-1.241.799-.888 1.06-2.908 1.06-2.908s.266-2.37.266-4.741V9.279c0-2.37-.267-4.741-.267-4.741Z"
          fill={colorString}
        />
        <path
          fill={colorString === "#ffffff" ? "#f00" : "#FFF"}
          d="M9.848 6.152v9.696L18.333 11z"
        />
      </g>
    </svg>
  )
}

export default YouTube

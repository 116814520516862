import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Facebook icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Facebook: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 12 20" {...remainingProps}>
      <title>Like us on Facebook</title>
      <path
        d="M7.5 20v-8.75h3.75l.625-3.75H7.5V5.625c0-1.25.626-1.875 1.875-1.875h1.875V0H8.125C5 0 3.75 1.875 3.75 5v2.5H0v3.75h3.75V20H7.5Z"
        fill={colorString}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default Facebook

import { Buffer } from "buffer"
import React, {
  createContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react"
import { LocationState } from "."
import { DealersClient } from "../../clients/DealersClient"
import { LocalStorageClient } from "../../clients/LocalStorageClient"
import { LocationClient } from "../../clients/LocationClient"
import useLocalStorage from "../../hooks/useLocalStorage"
import {
  setDealers,
  setFetchingLocationData,
  setMultiple,
  setOutOfRange,
  toggleDealersLoading,
  setErrorMessage,
} from "./actions"
import { LocationInfo } from "./location.d"
import locationReducer, { defaultState } from "./reducers"
import { slugify } from "../../helpers"
import { Dealer } from "../../global"

export const LocationContext = createContext<
  [LocationState, React.Dispatch<any>, (query?: string) => any]
>([defaultState, () => {}, () => {}])

export const LocationProvider: React.FC = ({ children }) => {
  const [locationState, dispatch] = useReducer(locationReducer, {
    ...defaultState,
  })
  const [locationData, writeLocationData] = useLocalStorage("locationData")
  const initialLoad = useRef(true)
  const [currentPath, setCurrentPath] = useState(window.location.pathname)

  const updateDealers = async () => {
    dispatch(toggleDealersLoading())

    const zipToUse = locationState.mapSearchZip || locationState.zip

    // prevent the dealers query from failing if the zip is not a number
    if (isNaN(zipToUse) && locationState.dealers) {
      dispatch(
        setDealers(
          locationState.dealers?.map((dealer: Dealer) => ({
            ...dealer,
            Slug: slugify(dealer?.Name),
          })),
        ),
      )
      dispatch(toggleDealersLoading())
    } else {
      const dealers = await DealersClient.getForLocation(
        zipToUse,
        locationState.radius,
      )
      dispatch(
        setDealers(
          dealers?.map(dealer => ({
            ...dealer,
            Slug: slugify(dealer?.Name),
          })),
        ),
      )
      dispatch(toggleDealersLoading())
    }
  }

  const updateLocationInfo = async (
    query?: string,
    initialLoad = false,
  ): Promise<LocationInfo> => {
    dispatch(setErrorMessage(null))
    dispatch(setFetchingLocationData(true))
    try {
      const {
        cloudFrontViewerPostalCode,
        cloudFrontViewerCity,
        cloudFrontViewerCountryRegionName,
        cloudFrontViewerCountryRegion,
        latitude,
        longitude,
        user_ip,
        geocoded_zip,
      } = await LocationClient.getLocationInfo(query)

      if (["ks"].includes(cloudFrontViewerCountryRegion.toLowerCase())) {
        // this case happens when a user scrolls the map to where the center point is over the ocean
        // this causes the geolocation to put them in the center of the US
        // we need to reset the user so they are back to a valid area of the map -- to do this, we will just use their existing geocoded zip
        // other potential solutions change the lat/long a little bit in each direction until we find a valid zip
        // but this is the simplest solution
        updateLocationInfo(geocoded_zip, false)
      }

      if (
        !["al", "ga", "sc", "nc", "fl"].includes(
          cloudFrontViewerCountryRegion.toLowerCase(),
        )
      ) {
        const confirmedOutOfRange = LocalStorageClient.read(
          "confirmedOutOfRange",
        )
        if (initialLoad) {
          if (!confirmedOutOfRange) {
            dispatch(
              setOutOfRange(
                true,
                cloudFrontViewerPostalCode.toString(),
                cloudFrontViewerCountryRegionName,
                user_ip,
              ),
            )
          } else {
            dispatch(
              setOutOfRange(
                false,
                cloudFrontViewerPostalCode.toString(),
                cloudFrontViewerCountryRegionName,
                user_ip,
              ),
            )
          }
        } else {
          dispatch(
            setOutOfRange(
              true,
              cloudFrontViewerPostalCode.toString(),
              cloudFrontViewerCountryRegionName,
              user_ip,
            ),
          )
        }

        dispatch(setFetchingLocationData(false))
        return {
          zip: null,
          city: null,
          state: null,
          latLng: {
            lat: null,
            lng: null,
          },
          isOutOfRange: {
            value: true,
          },
          user_ip: user_ip,
          geocoded_zip: geocoded_zip,
        }
      }

      const newLocationInfo = {
        zip: cloudFrontViewerPostalCode,
        city: cloudFrontViewerCity,
        state: cloudFrontViewerCountryRegionName,
        latLng: {
          lat: latitude,
          lng: longitude,
        },
        isOutOfRange: {
          value: false,
        },
        geocoded_zip,
        user_ip: "",
      }
      // Set user IP to locationState, but do not save it to local storage
      dispatch(setMultiple({ ...newLocationInfo, user_ip }))
      writeLocationData(newLocationInfo)
      dispatch(setFetchingLocationData(false))

      return newLocationInfo
    } catch (e) {
      dispatch(
        setErrorMessage(
          "Invalid input. Please enter zip, state, city, or dealer name.",
        ),
      )
      dispatch(setFetchingLocationData(false))
      LocalStorageClient.write("confirmedZip", false)
    }
  }

  // Get ZIP if not set
  useEffect(() => {
    if (locationData) {
      let { zip, city, state, geocoded_zip } = locationData
      dispatch(setMultiple({ zip, city, state, geocoded_zip }))
      return
    } else {
      try {
        updateLocationInfo(null, true)
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  // Update dealers and location data when ZIP or radius changes
  useEffect(() => {
    const zipToUse = locationState.mapSearchZip || locationState.zip

    if (!zipToUse) return

    let zip = zipToUse

    // override the zip if coming into the inventory page using a source zip
    if (currentPath === "/inventory/") {
      let decodedQueryParams
      const encodedQueryParams = location?.search?.substring(3)
      const decodedQueryParamsString = Buffer.from(
        encodedQueryParams,
        "base64",
      )?.toString("utf8")
      decodedQueryParams =
        decodedQueryParamsString && JSON.parse(decodedQueryParamsString)
      if (decodedQueryParams.sourceZip) {
        LocalStorageClient.write("confirmedZip", true)
        zip = decodedQueryParams.sourceZip
      }
    }

    if (!locationState.mapSearchZip) {
      updateLocationInfo(zip, initialLoad.current)
    }

    updateDealers()
    initialLoad.current = false
  }, [locationState.zip, locationState.radius, locationState.mapSearchZip])

  return (
    <LocationContext.Provider
      value={[locationState, dispatch, updateLocationInfo]}
    >
      {children}
    </LocationContext.Provider>
  )
}

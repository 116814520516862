import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * FAQs icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const FAQs: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 72 72" {...remainingProps}>
      <g fill={colorString} fillRule="evenodd">
        <path d="M33.498 50.24c.46-1.326 1.313-2.907 2.558-4.712 1.242-1.804 3.358-4.501 6.34-7.928 2.908-3.354 4.993-6.083 6.266-8.337 1.27-2.25 1.905-4.958 1.905-8.158-.002-4.189-1.267-7.745-3.815-10.524-2.546-2.767-6.272-4.35-11.176-4.355-4.552 0-8.17 1.447-10.859 4.041-2.69 2.603-4.415 8.014-5.188 11.761A112.391 112.391 0 0 0 15 20.831c.77-4.701 3.008-11.084 6.748-14.52C25.488 2.887 30.138.994 35.682 1c4.195.008 7.835 1.087 10.91 3.058 3.08 1.977 5.42 4.624 7.035 7.795a22.079 22.079 0 0 1 2.418 10.12c0 4.127-.822 7.537-2.47 10.294-1.652 2.76-4.062 5.7-7.247 8.982-3.326 3.348-5.495 6.05-6.488 7.887-.996 1.843-1.53 3.771-1.6 5.759a101.55 101.55 0 0 0-5.435 0c0-1.777.23-3.33.693-4.656M32.235 69.722c-.884-.887-1.325-1.973-1.325-3.254 0-1.35.44-2.468 1.325-3.358.882-.89 1.962-1.334 3.235-1.334s2.369.444 3.289 1.334c.916.89 1.377 2.007 1.377 3.358 0 1.281-.46 2.367-1.377 3.254-.92.886-2.016 1.332-3.289 1.332-1.273 0-2.353-.446-3.235-1.332" />
      </g>
    </svg>
  )
}

export default FAQs

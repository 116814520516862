import React from "react"
import { IconProps } from "./Icon.d"

/**
 * English icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */

const English: React.FC<IconProps> = ({ ...remainingProps }) => {
  return (
    <svg viewBox="0 0 76 76" fill="none" {...remainingProps}>
      <rect
        x="2"
        y="2"
        width="72"
        height="72"
        rx="10"
        stroke="#444445"
        strokeWidth="4"
      />
      <path
        d="M14 23H31.4562V27.8606H19.5449V35.131H31.0866V39.8282H19.5449V47.1394H31.7848V52H14V23Z"
        fill="#444445"
      />
      <path
        d="M63 23V52H57.7015L43.2847 31.4549V52H37.9863V23H43.3258L57.7015 43.5042V23H63Z"
        fill="#444445"
      />
    </svg>
  )
}

export default English

import React from "react"
import { IconProps } from "./Icon.d"

/**
 * Inspection icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Inspection = ({ ...remainingProps }: IconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 72 72" {...remainingProps}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M49.732 58.129a.952.952 0 0 1-.95.953H8.852a.952.952 0 0 1-.95-.953V6.67c0-.527.425-.953.95-.953h8.556v6.67c0 .527.426.953.951.953h20.916c.524 0 .95-.426.95-.953v-6.67h8.448c.285-.005.561.1.774.29.185.17.29.412.285.663V58.13ZM24.948 5.719c.525 0 .95-.428.95-.954a2.813 2.813 0 0 1 2.766-2.86c.041-.001.083 0 .125.002.8-.023 1.572.3 2.12.885.487.541.759 1.244.76 1.973 0 .526.426.953.95.953h5.705v5.717H19.31V5.718h5.638Zm25.797-1.102a3.042 3.042 0 0 0-2.072-.805H33.46a4.854 4.854 0 0 0-1.178-2.316A4.716 4.716 0 0 0 28.789.001a4.708 4.708 0 0 0-4.696 3.811H8.853A2.856 2.856 0 0 0 6 6.672v51.457a2.856 2.856 0 0 0 2.852 2.859h39.93a2.827 2.827 0 0 0 2.852-2.802V6.671a2.793 2.793 0 0 0-.889-2.054ZM20.892 47.184h21.677v-1.551H20.892v1.551Zm0-7.76h21.677v-1.55H20.892v1.55Zm0-7.759h21.677v-1.551H20.892v1.551Zm0-7.76h21.677v-1.552H20.892v1.551Z"
          stroke="#000"
          fill="#000"
        />
        <path
          d="m45.844 68.14 2.229.94.445-.498a4.704 4.704 0 0 1 6.998 0l.446.497 2.229-.939-.033-.67a4.711 4.711 0 0 1 4.942-4.957l.668.034.936-2.236-.496-.447a4.737 4.737 0 0 1 0-7.018l.496-.448-.936-2.235-.668.033a4.708 4.708 0 0 1-4.942-4.957l.033-.67-2.229-.939-.446.498a4.704 4.704 0 0 1-6.998 0l-.445-.498-2.23.94.034.67a4.711 4.711 0 0 1-4.942 4.956l-.668-.033-.936 2.235.495.448a4.737 4.737 0 0 1 0 7.018l-.495.447.936 2.236.668-.034a4.708 4.708 0 0 1 4.942 4.957l-.033.67Zm9.99 2.163a.559.559 0 0 1-.412-.184l-.713-.788a3.587 3.587 0 0 0-5.338 0l-.713.788a.556.556 0 0 1-.624.14l-2.981-1.236a.559.559 0 0 1-.34-.558l.05-1.062a3.6 3.6 0 0 0-3.772-3.783l-1.065.05a.556.556 0 0 1-.556-.34l-1.215-2.98a.56.56 0 0 1 .14-.626l.785-.715a3.611 3.611 0 0 0 0-5.353l-.786-.716a.56.56 0 0 1-.14-.626l1.232-2.99a.556.556 0 0 1 .557-.34l1.064.05a3.598 3.598 0 0 0 3.773-3.783l-.05-1.062a.559.559 0 0 1 .34-.559l2.98-1.235a.555.555 0 0 1 .624.14l.714.788a3.586 3.586 0 0 0 5.337 0l.713-.788a.556.556 0 0 1 .625-.14l2.98 1.235a.56.56 0 0 1 .34.559l-.05 1.062a3.6 3.6 0 0 0 3.772 3.783l1.064-.05c.24-.02.465.118.558.34l1.23 2.99a.56.56 0 0 1-.138.626l-.786.716a3.613 3.613 0 0 0 0 5.353l.786.715a.56.56 0 0 1 .139.626l-1.231 2.99a.557.557 0 0 1-.558.341l-1.064-.05a3.598 3.598 0 0 0-3.772 3.783l.05 1.062a.56.56 0 0 1-.34.559l-2.98 1.235a.467.467 0 0 1-.23.033Z"
          stroke="#FFF"
          fill="#FFF"
        />
        <path
          d="m44.153 69.835 2.23.938.445-.497a4.704 4.704 0 0 1 6.998 0l.446.497 2.228-.938-.033-.67a4.711 4.711 0 0 1 4.943-4.958l.668.034.936-2.236-.496-.447a4.737 4.737 0 0 1 0-7.018l.496-.448-.936-2.235-.668.034a4.708 4.708 0 0 1-4.943-4.957l.033-.67-2.228-.94-.446.498a4.704 4.704 0 0 1-6.998 0l-.446-.497-2.229.938.034.67a4.711 4.711 0 0 1-4.942 4.958l-.669-.034-.936 2.235.496.448a4.737 4.737 0 0 1 0 7.018l-.496.447.936 2.236.669-.034a4.708 4.708 0 0 1 4.942 4.957l-.034.67Zm9.99 2.162a.559.559 0 0 1-.412-.184l-.713-.788a3.587 3.587 0 0 0-5.337 0l-.713.788a.556.556 0 0 1-.624.14l-2.981-1.236a.559.559 0 0 1-.34-.558l.05-1.062a3.6 3.6 0 0 0-3.773-3.783l-1.064.05a.556.556 0 0 1-.557-.34l-1.214-2.98a.56.56 0 0 1 .139-.626l.786-.715a3.611 3.611 0 0 0 0-5.353l-.786-.715a.56.56 0 0 1-.14-.626l1.232-2.99a.556.556 0 0 1 .557-.34l1.064.05a3.598 3.598 0 0 0 3.773-3.784l-.05-1.062a.559.559 0 0 1 .34-.558l2.98-1.236a.555.555 0 0 1 .624.14l.714.788a3.586 3.586 0 0 0 5.337 0l.713-.788a.556.556 0 0 1 .624-.14l2.981 1.236a.56.56 0 0 1 .34.558l-.05 1.062a3.6 3.6 0 0 0 3.772 3.783l1.064-.05c.24-.02.465.118.558.34l1.23 2.99a.56.56 0 0 1-.138.627l-.786.715a3.613 3.613 0 0 0 0 5.353l.786.715a.56.56 0 0 1 .139.626l-1.231 2.99a.557.557 0 0 1-.558.342l-1.064-.051a3.598 3.598 0 0 0-3.772 3.783l.05 1.062a.56.56 0 0 1-.34.56l-2.98 1.234a.467.467 0 0 1-.23.033Z"
          stroke="#FFF"
          fill="#FFF"
        />
        <path
          d="M55.834 71.997a.559.559 0 0 1-.412-.184l-.713-.788a3.587 3.587 0 0 0-5.338 0l-.713.788a.556.556 0 0 1-.624.14l-2.981-1.236a.559.559 0 0 1-.34-.558l.05-1.062a3.6 3.6 0 0 0-3.772-3.783l-1.065.05a.556.556 0 0 1-.556-.34l-1.215-2.98a.56.56 0 0 1 .14-.626l.785-.715a3.611 3.611 0 0 0 0-5.353l-.786-.715a.56.56 0 0 1-.14-.626l1.232-2.99a.556.556 0 0 1 .557-.34l1.064.05a3.598 3.598 0 0 0 3.773-3.784l-.05-1.062a.559.559 0 0 1 .34-.558l2.98-1.236a.555.555 0 0 1 .624.14l.714.788a3.586 3.586 0 0 0 5.337 0l.713-.788a.556.556 0 0 1 .625-.14l2.98 1.236a.56.56 0 0 1 .34.558l-.05 1.062a3.6 3.6 0 0 0 3.772 3.783l1.064-.05c.24-.02.465.118.558.34l1.23 2.99a.56.56 0 0 1-.138.627l-.786.715a3.613 3.613 0 0 0 0 5.353l.786.715a.56.56 0 0 1 .139.626l-1.231 2.99a.557.557 0 0 1-.558.342l-1.064-.051a3.598 3.598 0 0 0-3.772 3.783l.05 1.062a.56.56 0 0 1-.34.56l-2.98 1.234a.467.467 0 0 1-.23.033Z"
          fill="#FFF"
        />
        <path
          d="m56.063 44.09 2.98 1.235a.56.56 0 0 1 .34.558l-.05 1.062a3.6 3.6 0 0 0 3.772 3.783l1.064-.05c.24-.02.465.118.558.34l1.23 2.99a.56.56 0 0 1-.138.627l-.786.715a3.613 3.613 0 0 0 0 5.353l.786.715a.56.56 0 0 1 .139.626l-1.231 2.99a.557.557 0 0 1-.558.342l-1.064-.051a3.598 3.598 0 0 0-3.772 3.783l.05 1.062a.56.56 0 0 1-.34.56l-2.98 1.234a.467.467 0 0 1-.23.033.559.559 0 0 1-.411-.184l-.713-.788a3.587 3.587 0 0 0-5.338 0l-.713.788a.556.556 0 0 1-.624.14l-2.981-1.236a.559.559 0 0 1-.34-.558l.05-1.062a3.6 3.6 0 0 0-3.772-3.783l-1.065.05a.556.556 0 0 1-.556-.34l-1.215-2.98a.56.56 0 0 1 .14-.626l.785-.715a3.611 3.611 0 0 0 0-5.353l-.786-.715a.56.56 0 0 1-.14-.626l1.232-2.99a.556.556 0 0 1 .557-.34l1.064.05a3.598 3.598 0 0 0 3.773-3.784l-.05-1.062a.559.559 0 0 1 .34-.558l2.98-1.236a.555.555 0 0 1 .624.14l.714.788a3.586 3.586 0 0 0 5.337 0l.713-.788a.556.556 0 0 1 .625-.14Zm-.1 1.235-.447.497a4.704 4.704 0 0 1-6.998 0l-.445-.497-2.23.938.034.67a4.711 4.711 0 0 1-4.942 4.958l-.668-.034-.936 2.235.495.448a4.737 4.737 0 0 1 0 7.018l-.495.447.936 2.236.668-.034a4.708 4.708 0 0 1 4.942 4.957l-.033.67 2.229.94.445-.498a4.704 4.704 0 0 1 6.998 0l.446.497 2.229-.938-.033-.67a4.711 4.711 0 0 1 4.942-4.958l.668.034.936-2.236-.496-.447a4.737 4.737 0 0 1 0-7.018l.496-.448-.936-2.235-.668.034a4.708 4.708 0 0 1-4.942-4.957l.033-.67-2.229-.94Zm-3.484 4.651c4.2 0 7.606 3.414 7.606 7.624 0 4.21-3.406 7.624-7.606 7.624-4.2 0-7.606-3.414-7.606-7.624 0-4.21 3.406-7.624 7.606-7.624Zm0 1.271c-3.5 0-6.338 2.845-6.338 6.353 0 3.508 2.838 6.353 6.338 6.353s6.338-2.845 6.338-6.353c0-3.508-2.838-6.353-6.338-6.353Z"
          stroke="#000"
          strokeWidth="1.25"
          fill="#010101"
          strokeLinejoin="round"
        />
        <path
          fill="#C00"
          d="m18.022 43.532-2.549 2.556-1.002-1.004-1.095 1.098 2.097 2.1 3.643-3.653zM18.022 35.772l-2.549 2.555-1.002-1.003-1.095 1.097 2.097 2.101 3.643-3.653zM18.022 28.012l-2.549 2.555-1.002-1.003-1.095 1.097 2.097 2.101 3.643-3.653zM18.022 20.251l-2.549 2.557-1.002-1.004-1.095 1.098 2.097 2.1 3.643-3.653z"
        />
      </g>
    </svg>
  )
}

export default Inspection

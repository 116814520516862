import React, { useEffect, useRef, useState } from "react"
import { IconProps } from "./Icon.d"

/**
 * Efficient icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Efficient = ({ ...remainingProps }: IconProps): JSX.Element => {
  const [iconHeight, setIconHeight] = useState(0)

  const ref = useRef(null)

  useEffect(() => {
    setIconHeight(ref.current.clientHeight)
  }, [])
  return (
    <svg ref={ref} {...remainingProps} viewBox="0 -1 72 74">
      <defs>
        <path
          id="g"
          d="m22.94 72 6.267-29.21-27.256 4.545L27.935 0l-6.134 29.479 27.198-4.55z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        {iconHeight >= 48 ? (
          <g transform="translate(12)">
            <path
              d="M22.7 47.508a.773.773 0 1 0-.295-1.517.773.773 0 0 0 .296 1.517Zm1.707-.519a.773.773 0 1 1 .294 1.518.773.773 0 0 1-.294-1.518Zm1.222-1.813a.773.773 0 1 1 .294 1.518.773.773 0 0 1-.294-1.518Zm-2.002-1a.773.773 0 1 1 .294 1.518.773.773 0 0 1-.294-1.518Zm2.783-1.187a.773.773 0 1 1 .294 1.518.773.773 0 0 1-.294-1.518Zm-6.742 5.109a.773.773 0 1 0-.296-1.517.773.773 0 0 0 .296 1.517Zm1.221-1.813a.773.773 0 1 0-.296-1.518.773.773 0 0 0 .296 1.518Zm-4.254 2.402a.773.773 0 1 0-.296-1.517.773.773 0 0 0 .296 1.517Zm1.221-1.813a.773.773 0 1 0-.296-1.517.773.773 0 0 0 .296 1.517Zm-4.254 2.403a.773.773 0 1 0-.296-1.517.773.773 0 0 0 .296 1.517Zm1.221-1.813a.773.773 0 1 0-.296-1.518.773.773 0 0 0 .296 1.518Zm-4.254 2.403a.773.773 0 1 0-.296-1.518.773.773 0 0 0 .296 1.518Zm1.221-1.814a.773.773 0 1 0-.296-1.517.773.773 0 0 0 .296 1.517Zm-4.254 2.403a.773.773 0 1 0-.296-1.517.773.773 0 0 0 .296 1.517Zm1.221-1.813a.773.773 0 1 0-.296-1.518.773.773 0 0 0 .296 1.518Zm-4.55.884a.773.773 0 1 1 .294 1.518.773.773 0 0 1-.293-1.518Zm1.222-1.813a.773.773 0 1 1 .294 1.517.773.773 0 0 1-.294-1.517Zm-3.96 3.921a.773.773 0 1 0-.295-1.517.773.773 0 0 0 .296 1.517Zm14.015-1.003a.773.773 0 1 0-.296-1.517.773.773 0 0 0 .296 1.517Zm-12.793-.81a.773.773 0 1 0-.296-1.517.773.773 0 0 0 .296 1.517Zm19.198-.538a.773.773 0 1 0-.296-1.517.773.773 0 0 0 .296 1.517Zm-3.033.59a.773.773 0 1 0-.296-1.517.773.773 0 0 0 .296 1.517Zm-6.066 1.18a.773.773 0 1 0-.296-1.518.773.773 0 0 0 .296 1.517Zm-3.033.589a.773.773 0 1 0-.296-1.518.773.773 0 0 0 .296 1.518Zm-3.033.59a.773.773 0 1 0-.296-1.518.773.773 0 0 0 .296 1.517Zm-3.033.589a.773.773 0 1 0-.296-1.518.773.773 0 0 0 .296 1.518Z"
              fill="#C00"
            />
            <use stroke="#000" strokeWidth="3" xlinkHref="#g" />
          </g>
        ) : null}
        <path
          stroke="#000"
          strokeWidth="3"
          d="m34.94 72 6.267-29.21-27.256 4.545L39.935 0l-6.134 29.479 27.198-4.55z"
        />
      </g>
    </svg>
  )
}

export default Efficient

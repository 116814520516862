import React from "react"
import { IconProps } from "./Icon.d"

/**
 * Info icon
 *
 * @param {IconProps} props
 *
 * @returns {JSX.Element} <svg />
 */

const DisclaimerInfo = ({ ...remainingProps }: IconProps): JSX.Element => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18.0 18.0"
      style={{ fill: "currentColor" }}
      {...remainingProps}
    >
      <path
        fill="transparent"
        d="M1440,0 L1440,6881 L0,6881 L0,0 L1440,0 Z"
      ></path>
      <path d="M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M9.28718182,7.2 L6.3,7.2 L6.3,9.57830519 L7.40454545,9.57830519 L7.40454545,14.4524689 C7.4070524,15.3404578 7.97533745,16.0872485 8.73,16.1942657 L11.7,16.2 L11.7,13.8216948 L10.7893636,13.8216948 L10.7893636,8.95469895 C10.7866693,7.98691213 10.1156953,7.2031473 9.28718182,7.2 Z M9,2.7 C8.00588745,2.7 7.2,3.50588745 7.2,4.5 C7.2,5.49411255 8.00588745,6.3 9,6.3 C9.99411255,6.3 10.8,5.49411255 10.8,4.5 C10.8,3.50588745 9.99411255,2.7 9,2.7 Z"></path>
    </svg>
  )
}

export default DisclaimerInfo

import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Globe icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Globe: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 24 24" {...remainingProps}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M10.112 2c5.217 0 9.508 4.098 10.053 9.364H19.14a9.582 9.582 0 0 0-1.9-4.744c-.657.367-1.39.683-2.199.945.33 1.167.527 2.433.605 3.799h-1.013c-.08-1.265-.272-2.446-.578-3.51-1.087.262-2.25.42-3.438.446v3.064h-.005v1.813l.005.01V16.7a20.58 20.58 0 0 1 2.314.223l.747 1.21a14.875 14.875 0 0 0-3.06-.383v4.148c1.263-.316 2.376-1.733 3.109-3.754l-.017.041.696 1.124c-.366.82-.802 1.533-1.308 2.116a9.042 9.042 0 0 0 1.99-1.013l.548.885A9.802 9.802 0 0 1 10.112 23C4.525 23 0 18.301 0 12.5S4.525 2 10.112 2Zm-.506 15.75c-1.087.026-2.123.184-3.11.394.734 2.021 1.846 3.412 3.11 3.727Zm-4.12.656c-.658.21-1.264.473-1.82.762.985.997 2.174 1.785 3.488 2.257-.682-.788-1.239-1.811-1.668-3.019Zm-.936-5.381v.026H1.036a9.585 9.585 0 0 0 1.947 5.329c.657-.367 1.39-.683 2.2-.945-.38-1.339-.582-2.809-.633-4.41Zm5.056 0H5.561c.051 1.496.253 2.887.607 4.121 1.087-.262 2.25-.42 3.438-.446v-3.675ZM2.958 6.646a9.441 9.441 0 0 0-1.947 5.329h3.54c.05-1.601.277-3.097.606-4.384a12.234 12.234 0 0 1-2.2-.945Zm3.21 1.208c-.354 1.234-.556 2.625-.607 4.121h4.045V8.3c-1.188-.053-2.35-.184-3.438-.446Zm3.438-4.752c-1.264.316-2.376 1.733-3.11 3.754a14.85 14.85 0 0 0 3.11.394Zm1.011 0V7.25a14.842 14.842 0 0 0 3.11-.42c-.733-2.021-1.846-3.413-3.11-3.728Zm-3.463.473a8.862 8.862 0 0 0-3.488 2.284c.556.288 1.162.525 1.82.735.455-1.234 1.01-2.258 1.668-3.019Zm5.915 0c.683.787 1.24 1.811 1.669 3.019.657-.21 1.264-.473 1.82-.762-.986-.997-2.174-1.785-3.489-2.257Z"
          fill={colorString}
        />
        <path
          stroke={colorString}
          strokeLinejoin="round"
          d="m20.476 12.666-8.676-.049 4.603 7.542.998-3.207 5.658 3.217.941-1.733-5.721-3.252z"
        />
      </g>
    </svg>
  )
}

export default Globe

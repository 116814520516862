import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Available icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Available = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg fill="none" viewBox="0 0 18 17" {...remainingProps}>
      <circle
        cx="9.265"
        cy="8.5"
        r="7.5"
        fill={colorString}
        fillOpacity=".2"
        stroke={colorString}
        strokeWidth="2"
      />
    </svg>
  )
}

export default Available

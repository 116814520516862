import React from "react"
import { IconProps } from "./Icon.d"

/**
 * Fluids icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Fluids = ({ ...remainingProps }: IconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 72 72" {...remainingProps}>
      <defs>
        <path id="ccc" d="M2.826 24.927h16.146V3.898H2.826z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fill="#000">
          <path d="M11.527 51.064c-1.278-1.576-3.42-1.8-4.986-.523-1.4 1.142-1.615 3.389-.46 4.819 1.23 1.522 3.406 1.735 4.958.485 1.411-1.136 1.638-3.363.488-4.781m12.43-6.32c-.648.506-1.292 1.018-1.947 1.515-.2.152-.223.272-.057.469.305.364.583.75.883 1.118.128.157.113.273-.044.387-.21.154-.41.325-.631.462-.343.213-.665.162-.943-.132a4.551 4.551 0 0 0-.681-.585c-.486-.344-1.007-.357-1.466-.021-1.1.803-2.233 1.573-3.179 2.565-.477.5-.676 1.137-.77 1.813-.086.625-.069 1.256-.162 1.881-.513 3.441-4.01 5.866-7.4 5.091-2.586-.591-4.263-2.195-4.848-4.773-.548-2.42.191-4.5 2.075-6.131 1.209-1.047 2.64-1.505 4.232-1.465.464.012.922.102 1.378.196.784.16 1.57.263 2.35-.024.347-.128.676-.294.968-.519.812-.626 1.624-1.255 2.426-1.894.7-.558.818-1.084.445-1.892a6.252 6.252 0 0 0-.306-.56c-.197-.333-.167-.646.099-.928.704-.75.768-.748 1.383.039.161.206.336.404.48.621.151.228.283.224.49.06 1.253-.989 2.513-1.969 3.773-2.95.336-.261.343-.261.61.079.965 1.227 1.923 2.46 2.892 3.684.129.162.088.232-.056.342-.67.511-1.33 1.034-1.993 1.551M45.613 25.484 26.667 40.286c-.34.265-.337.264-.077.603.51.668.524.504-.12 1.018-.356.283-.36.28-.632-.068l-1.934-2.476c-.238-.306-.236-.313.062-.55.616-.488.616-.488 1.101.127.235.296.235.297.544.056l23.386-18.271c5.08-3.97 10.16-7.937 15.236-11.911.229-.18.423-.229.708-.135.994.327 1.996.63 3 .926.211.063.257.147.187.35-.122.357-.23.72-.33 1.084-.053.197-.147.236-.338.175-.739-.234-1.485-.445-2.221-.686-.262-.087-.446-.036-.66.13-6.318 4.946-12.642 9.885-18.966 14.826" />
        </g>
        <path
          d="M57.256 68.003H35.277a4.013 4.013 0 0 1-4.003-4.014V29.836c0-.194.049-.385.142-.555 1.97-3.613 4.113-7.666 4.471-8.529v-4.534c0-.781.634-1.418 1.415-1.418h6.514a1.3 1.3 0 0 1 1.297 1.3v4.27c1.738 1.11 8.993 5.018 15.526 8.44.38.2.62.595.62 1.026v34.153a4.013 4.013 0 0 1-4.003 4.014"
          fill="#FFF"
        />
        <g transform="translate(35.175 34.644)">
          <mask id="ddd" fill="#fff">
            <use xlinkHref="#ccc" />
          </mask>
          <g mask="url(#ddd)" fill="#C00">
            <circle cx="21.673" cy="19.498" r="1" />
            <circle cx="21.673" cy="17.498" r="1" />
            <circle cx="21.673" cy="15.499" r="1" />
            <circle cx="21.673" cy="23.498" r="1" />
            <circle cx="21.673" cy="13.499" r="1" />
            <circle cx="21.673" cy="25.498" r="1" />
            <circle cx="21.673" cy="11.499" r="1" />
            <circle cx="21.673" cy="27.497" r="1" />
            <circle cx="20.665" cy="4.5" r="1" />
            <circle cx="21.673" cy="5.499" r="1" />
            <circle cx="20.665" cy="6.499" r="1" />
            <circle cx="21.673" cy="7.499" r="1" />
            <circle cx="21.673" cy="3.5" r="1" />
            <circle cx="18.649" cy="4.5" r="1" />
            <circle cx="19.657" cy="7.499" r="1" />
            <circle cx="19.657" cy="5.499" r="1" />
            <circle cx="18.649" cy="6.499" r="1" />
            <circle cx="19.657" cy="3.5" r="1" />
            <circle cx="20.665" cy="2.5" r="1" />
            <circle cx="20.665" cy="22.498" r="1" />
            <circle cx="20.665" cy="20.498" r="1" />
            <circle cx="20.665" cy="18.498" r="1" />
            <circle cx="20.665" cy="16.498" r="1" />
            <circle cx="20.665" cy="24.498" r="1" />
            <circle cx="20.665" cy="14.499" r="1" />
            <circle cx="20.665" cy="26.497" r="1" />
            <circle cx="20.665" cy="12.499" r="1" />
            <circle cx="20.665" cy="28.497" r="1" />
            <circle cx="20.665" cy="10.499" r="1" />
            <circle cx="20.665" cy="8.499" r="1" />
            <circle cx="21.673" cy="9.499" r="1" />
            <circle cx="21.673" cy="21.498" r="1" />
            <circle cx="20.665" cy=".5" r="1" />
            <circle cx="21.673" cy="1.5" r="1" />
            <circle cx="19.657" cy="21.498" r="1" />
            <circle cx="19.657" cy="19.498" r="1" />
            <circle cx="19.657" cy="17.498" r="1" />
            <circle cx="19.657" cy="15.499" r="1" />
            <circle cx="19.657" cy="23.498" r="1" />
            <circle cx="19.657" cy="13.499" r="1" />
            <circle cx="19.657" cy="25.498" r="1" />
            <circle cx="19.657" cy="11.499" r="1" />
            <circle cx="19.657" cy="27.497" r="1" />
            <circle cx="19.657" cy="9.499" r="1" />
            <circle cx="18.649" cy="2.5" r="1" />
            <circle cx="18.649" cy="22.498" r="1" />
            <circle cx="18.649" cy="20.498" r="1" />
            <circle cx="18.649" cy="18.498" r="1" />
            <circle cx="18.649" cy="16.498" r="1" />
            <circle cx="18.649" cy="24.498" r="1" />
            <circle cx="18.649" cy="14.499" r="1" />
            <circle cx="18.649" cy="26.497" r="1" />
            <circle cx="18.649" cy="12.499" r="1" />
            <circle cx="18.649" cy="28.497" r="1" />
            <circle cx="18.649" cy="10.499" r="1" />
            <circle cx="18.649" cy="8.499" r="1" />
            <circle cx="18.649" cy=".5" r="1" />
            <circle cx="19.657" cy="1.5" r="1" />
            <circle cx="17.641" cy="19.498" r="1" />
            <circle cx="17.641" cy="17.498" r="1" />
            <circle cx="17.641" cy="15.499" r="1" />
            <circle cx="17.641" cy="23.498" r="1" />
            <circle cx="17.641" cy="13.499" r="1" />
            <circle cx="17.641" cy="25.498" r="1" />
            <circle cx="17.641" cy="11.499" r="1" />
            <circle cx="17.641" cy="27.497" r="1" />
            <circle cx="16.633" cy="4.5" r="1" />
            <circle cx="17.641" cy="5.499" r="1" />
            <circle cx="16.633" cy="6.499" r="1" />
            <circle cx="17.641" cy="7.499" r="1" />
            <circle cx="17.641" cy="3.5" r="1" />
            <circle cx="14.617" cy="4.5" r="1" />
            <circle cx="15.625" cy="7.499" r="1" />
            <circle cx="15.625" cy="5.499" r="1" />
            <circle cx="14.617" cy="6.499" r="1" />
            <circle cx="15.625" cy="3.5" r="1" />
            <circle cx="16.633" cy="2.5" r="1" />
            <circle cx="16.633" cy="22.498" r="1" />
            <circle cx="16.633" cy="20.498" r="1" />
            <circle cx="16.633" cy="18.498" r="1" />
            <circle cx="16.633" cy="16.498" r="1" />
            <circle cx="16.633" cy="24.498" r="1" />
            <circle cx="16.633" cy="14.499" r="1" />
            <circle cx="16.633" cy="26.497" r="1" />
            <circle cx="16.633" cy="12.499" r="1" />
            <circle cx="16.633" cy="28.497" r="1" />
            <circle cx="16.633" cy="10.499" r="1" />
            <circle cx="16.633" cy="8.499" r="1" />
            <circle cx="17.641" cy="9.499" r="1" />
            <circle cx="17.641" cy="21.498" r="1" />
            <circle cx="16.633" cy=".5" r="1" />
            <circle cx="17.641" cy="1.5" r="1" />
            <circle cx="15.625" cy="21.498" r="1" />
            <circle cx="15.625" cy="19.498" r="1" />
            <circle cx="15.625" cy="17.498" r="1" />
            <circle cx="15.625" cy="15.499" r="1" />
            <circle cx="15.625" cy="23.498" r="1" />
            <circle cx="15.625" cy="13.499" r="1" />
            <circle cx="15.625" cy="25.498" r="1" />
            <circle cx="15.625" cy="11.499" r="1" />
            <circle cx="15.625" cy="27.497" r="1" />
            <circle cx="15.625" cy="9.499" r="1" />
            <circle cx="14.617" cy="2.5" r="1" />
            <circle cx="14.617" cy="22.498" r="1" />
            <circle cx="14.617" cy="20.498" r="1" />
            <circle cx="14.617" cy="18.498" r="1" />
            <circle cx="14.617" cy="16.498" r="1" />
            <circle cx="14.617" cy="24.498" r="1" />
            <circle cx="14.617" cy="14.499" r="1" />
            <circle cx="14.617" cy="26.497" r="1" />
            <circle cx="14.617" cy="12.499" r="1" />
            <circle cx="14.617" cy="28.497" r="1" />
            <circle cx="14.617" cy="10.499" r="1" />
            <circle cx="14.617" cy="8.499" r="1" />
            <circle cx="14.617" cy=".5" r="1" />
            <circle cx="15.625" cy="1.5" r="1" />
            <circle cx="13.609" cy="19.498" r="1" />
            <circle cx="13.609" cy="17.498" r="1" />
            <circle cx="13.609" cy="15.499" r="1" />
            <circle cx="13.609" cy="23.498" r="1" />
            <circle cx="13.609" cy="13.499" r="1" />
            <circle cx="13.609" cy="25.498" r="1" />
            <circle cx="13.609" cy="11.499" r="1" />
            <circle cx="13.609" cy="27.497" r="1" />
            <circle cx="12.6" cy="4.5" r="1" />
            <circle cx="13.609" cy="5.499" r="1" />
            <circle cx="12.6" cy="6.499" r="1" />
            <circle cx="13.609" cy="7.499" r="1" />
            <circle cx="13.609" cy="3.5" r="1" />
            <circle cx="10.584" cy="4.5" r="1" />
            <circle cx="11.592" cy="7.499" r="1" />
            <circle cx="11.592" cy="5.499" r="1" />
            <circle cx="10.584" cy="6.499" r="1" />
            <circle cx="11.592" cy="3.5" r="1" />
            <circle cx="12.6" cy="2.5" r="1" />
            <circle cx="12.6" cy="22.498" r="1" />
            <circle cx="12.6" cy="20.498" r="1" />
            <circle cx="12.6" cy="18.498" r="1" />
            <circle cx="12.6" cy="16.498" r="1" />
            <circle cx="12.6" cy="24.498" r="1" />
            <circle cx="12.6" cy="14.499" r="1" />
            <circle cx="12.6" cy="26.497" r="1" />
            <circle cx="12.6" cy="12.499" r="1" />
            <circle cx="12.6" cy="28.497" r="1" />
            <circle cx="12.6" cy="10.499" r="1" />
            <circle cx="12.6" cy="8.499" r="1" />
            <circle cx="13.609" cy="9.499" r="1" />
            <circle cx="13.609" cy="21.498" r="1" />
            <circle cx="12.6" cy=".5" r="1" />
            <circle cx="13.609" cy="1.5" r="1" />
            <circle cx="11.592" cy="21.498" r="1" />
            <circle cx="11.592" cy="19.498" r="1" />
            <circle cx="11.592" cy="17.498" r="1" />
            <circle cx="11.592" cy="15.499" r="1" />
            <circle cx="11.592" cy="23.498" r="1" />
            <circle cx="11.592" cy="13.499" r="1" />
            <circle cx="11.592" cy="25.498" r="1" />
            <circle cx="11.592" cy="11.499" r="1" />
            <circle cx="11.592" cy="27.497" r="1" />
            <circle cx="11.592" cy="9.499" r="1" />
            <circle cx="10.584" cy="2.5" r="1" />
            <circle cx="10.584" cy="22.498" r="1" />
            <circle cx="10.584" cy="20.498" r="1" />
            <circle cx="10.584" cy="18.498" r="1" />
            <circle cx="10.584" cy="16.498" r="1" />
            <circle cx="10.584" cy="24.498" r="1" />
            <circle cx="10.584" cy="14.499" r="1" />
            <circle cx="10.584" cy="26.497" r="1" />
            <circle cx="10.584" cy="12.499" r="1" />
            <circle cx="10.584" cy="28.497" r="1" />
            <circle cx="10.584" cy="10.499" r="1" />
            <circle cx="10.584" cy="8.499" r="1" />
            <circle cx="10.584" cy=".5" r="1" />
            <circle cx="11.592" cy="1.5" r="1" />
            <circle cx="9.576" cy="19.498" r="1" />
            <circle cx="9.576" cy="17.498" r="1" />
            <circle cx="9.576" cy="15.499" r="1" />
            <circle cx="9.576" cy="23.498" r="1" />
            <circle cx="9.576" cy="13.499" r="1" />
            <circle cx="9.576" cy="25.498" r="1" />
            <circle cx="9.576" cy="11.499" r="1" />
            <circle cx="9.576" cy="27.497" r="1" />
            <circle cx="8.568" cy="4.5" r="1" />
            <circle cx="9.576" cy="5.499" r="1" />
            <circle cx="8.568" cy="6.499" r="1" />
            <circle cx="9.576" cy="7.499" r="1" />
            <circle cx="9.576" cy="3.5" r="1" />
            <circle cx="6.552" cy="4.5" r="1" />
            <circle cx="7.56" cy="7.499" r="1" />
            <circle cx="7.56" cy="5.499" r="1" />
            <circle cx="6.552" cy="6.499" r="1" />
            <circle cx="7.56" cy="3.5" r="1" />
            <circle cx="8.568" cy="2.5" r="1" />
            <circle cx="8.568" cy="22.498" r="1" />
            <circle cx="8.568" cy="20.498" r="1" />
            <circle cx="8.568" cy="18.498" r="1" />
            <circle cx="8.568" cy="16.498" r="1" />
            <circle cx="8.568" cy="24.498" r="1" />
            <circle cx="8.568" cy="14.499" r="1" />
            <circle cx="8.568" cy="26.497" r="1" />
            <circle cx="8.568" cy="12.499" r="1" />
            <circle cx="8.568" cy="28.497" r="1" />
            <circle cx="8.568" cy="10.499" r="1" />
            <circle cx="8.568" cy="8.499" r="1" />
            <circle cx="9.576" cy="9.499" r="1" />
            <circle cx="9.576" cy="21.498" r="1" />
            <circle cx="8.568" cy=".5" r="1" />
            <circle cx="9.576" cy="1.5" r="1" />
            <circle cx="7.56" cy="21.498" r="1" />
            <circle cx="7.56" cy="19.498" r="1" />
            <circle cx="7.56" cy="17.498" r="1" />
            <circle cx="7.56" cy="15.499" r="1" />
            <circle cx="7.56" cy="23.498" r="1" />
            <circle cx="7.56" cy="13.499" r="1" />
            <circle cx="7.56" cy="25.498" r="1" />
            <circle cx="7.56" cy="11.499" r="1" />
            <circle cx="7.56" cy="27.497" r="1" />
            <circle cx="7.56" cy="9.499" r="1" />
            <circle cx="6.552" cy="2.5" r="1" />
            <circle cx="6.552" cy="22.498" r="1" />
            <circle cx="6.552" cy="20.498" r="1" />
            <circle cx="6.552" cy="18.498" r="1" />
            <circle cx="6.552" cy="16.498" r="1" />
            <circle cx="6.552" cy="24.498" r="1" />
            <circle cx="6.552" cy="14.499" r="1" />
            <circle cx="6.552" cy="26.497" r="1" />
            <circle cx="6.552" cy="12.499" r="1" />
            <circle cx="6.552" cy="28.497" r="1" />
            <circle cx="6.552" cy="10.499" r="1" />
            <circle cx="6.552" cy="8.499" r="1" />
            <circle cx="6.552" cy=".5" r="1" />
            <circle cx="7.56" cy="1.5" r="1" />
            <circle cx="5.544" cy="19.498" r="1" />
            <circle cx="5.544" cy="17.498" r="1" />
            <circle cx="5.544" cy="15.499" r="1" />
            <circle cx="5.544" cy="23.498" r="1" />
            <circle cx="5.544" cy="13.499" r="1" />
            <circle cx="5.544" cy="25.498" r="1" />
            <circle cx="5.544" cy="11.499" r="1" />
            <circle cx="5.544" cy="27.497" r="1" />
            <circle cx="4.536" cy="4.5" r="1" />
            <circle cx="5.544" cy="5.499" r="1" />
            <circle cx="4.536" cy="6.499" r="1" />
            <circle cx="5.544" cy="7.499" r="1" />
            <circle cx="5.544" cy="3.5" r="1" />
            <circle cx="2.52" cy="4.5" r="1" />
            <circle cx="3.528" cy="7.499" r="1" />
            <circle cx="3.528" cy="5.499" r="1" />
            <circle cx="2.52" cy="6.499" r="1" />
            <circle cx="3.528" cy="3.5" r="1" />
            <circle cx="4.536" cy="2.5" r="1" />
            <circle cx="4.536" cy="22.498" r="1" />
            <circle cx="4.536" cy="20.498" r="1" />
            <circle cx="4.536" cy="18.498" r="1" />
            <circle cx="4.536" cy="16.498" r="1" />
            <circle cx="4.536" cy="24.498" r="1" />
            <circle cx="4.536" cy="14.499" r="1" />
            <circle cx="4.536" cy="26.497" r="1" />
            <circle cx="4.536" cy="12.499" r="1" />
            <circle cx="4.536" cy="28.497" r="1" />
            <circle cx="4.536" cy="10.499" r="1" />
            <circle cx="4.536" cy="8.499" r="1" />
            <circle cx="5.544" cy="9.499" r="1" />
            <circle cx="5.544" cy="21.498" r="1" />
            <circle cx="4.536" cy=".5" r="1" />
            <circle cx="5.544" cy="1.5" r="1" />
            <circle cx="3.528" cy="21.498" r="1" />
            <circle cx="3.528" cy="19.498" r="1" />
            <circle cx="3.528" cy="17.498" r="1" />
            <circle cx="3.528" cy="15.499" r="1" />
            <circle cx="3.528" cy="23.498" r="1" />
            <circle cx="3.528" cy="13.499" r="1" />
            <circle cx="3.528" cy="25.498" r="1" />
            <circle cx="3.528" cy="11.499" r="1" />
            <circle cx="3.528" cy="27.497" r="1" />
            <circle cx="3.528" cy="9.499" r="1" />
            <circle cx="2.52" cy="2.5" r="1" />
            <circle cx="2.52" cy="22.498" r="1" />
            <circle cx="2.52" cy="20.498" r="1" />
            <circle cx="2.52" cy="18.498" r="1" />
            <circle cx="2.52" cy="16.498" r="1" />
            <circle cx="2.52" cy="24.498" r="1" />
            <circle cx="2.52" cy="14.499" r="1" />
            <circle cx="2.52" cy="26.497" r="1" />
            <circle cx="2.52" cy="12.499" r="1" />
            <circle cx="2.52" cy="28.497" r="1" />
            <circle cx="2.52" cy="10.499" r="1" />
            <circle cx="2.52" cy="8.499" r="1" />
            <circle cx="2.52" cy=".5" r="1" />
            <circle cx="3.528" cy="1.5" r="1" />
            <circle cx=".504" cy="4.5" r="1" />
            <circle cx="1.512" cy="7.499" r="1" />
            <circle cx="1.512" cy="5.499" r="1" />
            <circle cx=".504" cy="6.499" r="1" />
            <circle cx="1.512" cy="3.5" r="1" />
            <circle cx="1.512" cy="21.498" r="1" />
            <circle cx="1.512" cy="19.498" r="1" />
            <circle cx="1.512" cy="17.498" r="1" />
            <circle cx="1.512" cy="15.499" r="1" />
            <circle cx="1.512" cy="23.498" r="1" />
            <circle cx="1.512" cy="13.499" r="1" />
            <circle cx="1.512" cy="25.498" r="1" />
            <circle cx="1.512" cy="11.499" r="1" />
            <circle cx="1.512" cy="27.497" r="1" />
            <circle cx="1.512" cy="9.499" r="1" />
            <circle cx=".504" cy="2.5" r="1" />
            <circle cx=".504" cy="22.498" r="1" />
            <circle cx=".504" cy="20.498" r="1" />
            <circle cx=".504" cy="18.498" r="1" />
            <circle cx=".504" cy="16.498" r="1" />
            <circle cx=".504" cy="24.498" r="1" />
            <circle cx=".504" cy="14.499" r="1" />
            <circle cx=".504" cy="26.497" r="1" />
            <circle cx=".504" cy="12.499" r="1" />
            <circle cx=".504" cy="28.497" r="1" />
            <circle cx=".504" cy="10.499" r="1" />
            <circle cx=".504" cy="8.499" r="1" />
            <circle cx=".504" cy=".5" r="1" />
            <circle cx="1.512" cy="1.5" r="1" />
          </g>
        </g>
        <path
          d="M54.147 36.23H38a2.31 2.31 0 0 0-2.306 2.313V59.57a2.31 2.31 0 0 0 2.306 2.314h16.146a2.31 2.31 0 0 0 2.306-2.314V38.543a2.31 2.31 0 0 0-2.306-2.314ZM38 59.57h16.146V38.543H38V59.57Z"
          stroke="#000"
          strokeWidth=".25"
          fill="#000"
        />
        <path
          d="M33.58 30.13V63.99c0 .937.762 1.701 1.697 1.701h21.979c.935 0 1.696-.764 1.696-1.701V30.536C42.805 22.07 42.807 21.8 42.807 20.929v-3.815h-4.613v3.815c0 .345 0 .735-4.613 9.202m23.675 37.872H35.277a4.013 4.013 0 0 1-4.003-4.014V29.836c0-.194.049-.385.142-.555 1.97-3.613 4.113-7.666 4.471-8.529v-4.534c0-.781.634-1.418 1.415-1.418h6.514a1.3 1.3 0 0 1 1.297 1.3v4.27c1.738 1.11 8.993 5.018 15.526 8.44.38.2.62.595.62 1.026v34.153a4.013 4.013 0 0 1-4.003 4.014M67.366 15.175 63.26 22.58c-1.6 2.885.814 6.433 4.106 6.433 3.293 0 5.707-3.548 4.107-6.433l-4.107-7.405Zm0 3.808 2.494 4.495c.53.957.18 1.832-.078 2.27-.4.684-1.245 1.414-2.416 1.414-1.17 0-2.015-.73-2.416-1.413-.257-.439-.607-1.314-.077-2.27l2.493-4.496Z"
          stroke="#000"
          strokeWidth=".25"
          fill="#000"
        />
        <path
          stroke="#000"
          strokeWidth=".25"
          fill="#000"
          d="M37.04 21.74h6.92v-2.314h-6.92z"
        />
      </g>
    </svg>
  )
}

export default Fluids

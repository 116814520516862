import React, { useEffect, useRef, useState } from "react"
import { IconProps } from "./Icon.d"

/**
 * SaveSearch icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const SaveSearch: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const [iconHeight, setIconHeight] = useState(0)

  const ref = useRef(null)

  useEffect(() => {
    setIconHeight(ref.current.clientHeight)
  }, [])

  return (
    <svg
      viewBox={
        iconHeight >= 48
          ? "-1 -1 74 74"
          : iconHeight <= 24
            ? "-1 -1 25 25"
            : "-1 -1 49 49"
      }
      {...remainingProps}
      ref={ref}
    >
      {iconHeight >= 48 ? (
        <g fill="none" fillRule="evenodd">
          <path
            d="M28.5 9a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-6 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-2 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm1 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm6 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm6 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm6 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm6 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-2 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-8 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm6 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-2 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-2 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm3 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-2 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
            fill="#C00"
          />
          <path
            d="M26.517 5.626c3.248 0 6.314.767 9.058 2.095a22.854 22.854 0 0 0-5.893-.792 22.598 22.598 0 0 0-16.087 6.665c-5.959 5.958-7.896 14.424-5.848 22.032a20.735 20.735 0 0 1-2.12-9.11c0-11.519 9.371-20.89 20.89-20.89"
            stroke="#000"
            fill="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.64 0C11.928 0 0 11.928 0 26.64s11.928 26.64 26.64 26.64 26.64-11.928 26.64-26.64S41.352 0 26.64 0m0 2.88c13.101 0 23.76 10.659 23.76 23.76S39.741 50.4 26.64 50.4 2.88 39.741 2.88 26.64 13.539 2.88 26.64 2.88"
            stroke="#000"
            fill="#000"
          />
          <path
            d="M70.56 72c-.369 0-.737-.141-1.018-.422l-25.92-25.92a1.438 1.438 0 0 1 0-2.036 1.438 1.438 0 0 1 2.036 0l25.92 25.92A1.438 1.438 0 0 1 70.56 72"
            stroke="#000"
            fill="#000"
          />
        </g>
      ) : iconHeight <= 24 ? (
        <g fill="#000" stroke="#000" strokeWidth="2" fillRule="evenodd">
          <path d="M8.88 0a8.88 8.88 0 1 0 0 17.76A8.88 8.88 0 0 0 8.88 0m0 .96c4.367 0 7.92 3.553 7.92 7.92s-3.553 7.92-7.92 7.92S.96 13.247.96 8.88 4.513.96 8.88.96" />
          <path d="M23.52 24a.479.479 0 0 1-.34-.14l-8.64-8.64a.48.48 0 1 1 .68-.68l8.64 8.64a.48.48 0 0 1-.34.82" />
        </g>
      ) : (
        <g fill="none" fillRule="evenodd">
          <path
            d="M24.166 5.802c-5.187 1.27-9.105 3.228-11.753 5.876-2.648 2.648-4.852 6.81-6.611 12.488-.484-1.99-.727-3.71-.727-5.163 0-7.68 6.248-13.927 13.927-13.927 1.444 0 3.165.242 5.164.726Z"
            stroke="#C6C6C6"
            fill="#C6C6C6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.268 4.342c2.165 0 4.209.51 6.038 1.397a15.236 15.236 0 0 0-3.928-.529A15.066 15.066 0 0 0 9.653 9.654c-3.973 3.972-5.264 9.616-3.9 14.688a13.823 13.823 0 0 1-1.412-6.073c0-7.68 6.247-13.927 13.927-13.927"
            stroke="#000"
            fill="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.76 0C7.952 0 0 7.952 0 17.76c0 9.808 7.952 17.76 17.76 17.76 9.808 0 17.76-7.952 17.76-17.76C35.52 7.952 27.568 0 17.76 0m0 1.92c8.734 0 15.84 7.106 15.84 15.84S26.494 33.6 17.76 33.6 1.92 26.494 1.92 17.76 9.026 1.92 17.76 1.92"
            stroke="#000"
            fill="#000"
          />
          <path
            d="M47.04 48a.957.957 0 0 1-.679-.281l-17.28-17.28a.959.959 0 1 1 1.358-1.358l17.28 17.28A.959.959 0 0 1 47.04 48"
            stroke="#000"
            fill="#000"
          />
        </g>
      )}
    </svg>
  )
}

export default SaveSearch

import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Info icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Info = ({ color, filled, ...remainingProps }: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 24 24" {...remainingProps}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M12 0c6.628 0 12 5.372 12 12s-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0"
          fill={colorString}
        />
        <path
          d="M10.946 18h2.043V9.839h-2.043V18Zm.164-10.895a1.204 1.204 0 0 1-.36-.879c0-.344.12-.635.36-.871.239-.237.539-.355.898-.355.349 0 .643.119.883.355.239.236.359.527.359.871s-.12.637-.36.879-.533.363-.882.363c-.359 0-.659-.121-.898-.363Z"
          stroke="#FFF"
          strokeWidth=".5"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default Info

import React from "react"
import { RefreshIconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Refresh icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Refresh: React.FC<RefreshIconProps> = ({
  colorA,
  colorB,
  ...remainingProps
}) => {
  const colorAString = processIconColor(colorA)
  const colorBString = processIconColor(colorB)

  return (
    <svg viewBox="0 0 48 48" {...remainingProps}>
      <path
        d="M24.2,38c-3.5,0-6.8-1.4-9.2-3.8c-0.3-0.3-1-1.1-1-1.2c-0.2-0.3-0.7-0.9-0.7-1c-3.3-5.1-2.6-12,1.7-16.3
	c0,0,0.7-0.7,1-0.9l0.1,7c0,0.5,0.2,0.9,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.3l-0.2-10.5
	c0-0.9-0.8-1.7-1.7-1.7H7.3c-1,0-1.7,0.8-1.7,1.7c0,0.5,0.2,0.9,0.5,1.3s0.8,0.5,1.3,0.5h5.2l-0.4,0.4c-3,3.1-4.6,7.1-4.6,11.4v0
	c0,3.2,0.9,6.3,2.7,9c0,0,0.6,0.8,0.9,1.2c0.1,0.1,0.9,1.1,1.3,1.5c3.1,3.1,7.3,4.9,11.7,4.9c1,0,1.7-0.8,1.7-1.7
	C25.9,38.9,25.1,38,24.2,38L24.2,38z"
        fill={colorAString}
        fillRule="evenodd"
      />
      <path
        d="M42.1,35.4c-0.3-0.3-0.8-0.5-1.3-0.5h-5.2l0.4-0.4c3-3.1,4.6-7.1,4.6-11.4V23c0-3.2-0.9-6.3-2.7-9
	c0,0-0.6-0.8-0.9-1.2c-0.1-0.1-0.9-1.1-1.3-1.5C32.6,8.1,28.4,6.4,24,6.4c-1,0-1.7,0.8-1.7,1.7c0,1,0.8,1.7,1.7,1.7
	c3.5,0,6.8,1.4,9.2,3.8c0.3,0.3,1,1.1,1,1.2c0.2,0.3,0.7,0.9,0.7,0.9c3.3,5.1,2.6,12-1.7,16.3c0,0-0.7,0.7-1,0.9l-0.2-7
	c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.3-0.5c-1,0-1.7,0.8-1.7,1.8l0.2,10.5c0,0.9,0.8,1.7,1.7,1.7h10.3c1,0,1.7-0.8,1.7-1.7
	C42.6,36.2,42.4,35.7,42.1,35.4L42.1,35.4z"
        fill={colorBString}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Refresh

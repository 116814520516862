import React from "react"
import { IconProps } from "./Icon.d"

/**
 * Audio icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */

const Audio: React.FC<IconProps> = ({ ...remainingProps }) => {
  return (
    <svg viewBox="0 0 72 72" {...remainingProps}>
      <path
        d="M11.63 27.6923C11.9359 27.6923 12.1839 27.4443 12.1839 27.1385C12.1839 26.8326 11.9359 26.5846 11.63 26.5846C11.3241 26.5846 11.0762 26.8326 11.0762 27.1385C11.0762 27.4443 11.3241 27.6923 11.63 27.6923Z"
        fill="#C00000"
      />
      <path
        d="M9.41517 25.4769C9.72106 25.4769 9.96902 25.229 9.96902 24.9231C9.96902 24.6172 9.72106 24.3692 9.41517 24.3692C9.10929 24.3692 8.86133 24.6172 8.86133 24.9231C8.86133 25.229 9.10929 25.4769 9.41517 25.4769Z"
        fill="#C00000"
      />
      <path
        d="M11.63 25.4769C11.9359 25.4769 12.1839 25.229 12.1839 24.9231C12.1839 24.6172 11.9359 24.3692 11.63 24.3692C11.3241 24.3692 11.0762 24.6172 11.0762 24.9231C11.0762 25.229 11.3241 25.4769 11.63 25.4769Z"
        fill="#C00000"
      />
      <path
        d="M10.5226 26.5846C10.8285 26.5846 11.0764 26.3366 11.0764 26.0308C11.0764 25.7249 10.8285 25.4769 10.5226 25.4769C10.2167 25.4769 9.96875 25.7249 9.96875 26.0308C9.96875 26.3366 10.2167 26.5846 10.5226 26.5846Z"
        fill="#C00000"
      />
      <path
        d="M10.5226 24.3692C10.8285 24.3692 11.0764 24.1213 11.0764 23.8154C11.0764 23.5095 10.8285 23.2615 10.5226 23.2615C10.2167 23.2615 9.96875 23.5095 9.96875 23.8154C9.96875 24.1213 10.2167 24.3692 10.5226 24.3692Z"
        fill="#C00000"
      />
      <path
        d="M8.30775 26.5846C8.61363 26.5846 8.8616 26.3366 8.8616 26.0308C8.8616 25.7249 8.61363 25.4769 8.30775 25.4769C8.00187 25.4769 7.75391 25.7249 7.75391 26.0308C7.75391 26.3366 8.00187 26.5846 8.30775 26.5846Z"
        fill="#C00000"
      />
      <path
        d="M9.41517 27.6923C9.72106 27.6923 9.96902 27.4443 9.96902 27.1385C9.96902 26.8326 9.72106 26.5846 9.41517 26.5846C9.10929 26.5846 8.86133 26.8326 8.86133 27.1385C8.86133 27.4443 9.10929 27.6923 9.41517 27.6923Z"
        fill="#C00000"
      />
      <path
        d="M7.20033 25.4769C7.50621 25.4769 7.75418 25.229 7.75418 24.9231C7.75418 24.6172 7.50621 24.3692 7.20033 24.3692C6.89445 24.3692 6.64648 24.6172 6.64648 24.9231C6.64648 25.229 6.89445 25.4769 7.20033 25.4769Z"
        fill="#C00000"
      />
      <path
        d="M8.30775 24.3692C8.61363 24.3692 8.8616 24.1213 8.8616 23.8154C8.8616 23.5095 8.61363 23.2615 8.30775 23.2615C8.00187 23.2615 7.75391 23.5095 7.75391 23.8154C7.75391 24.1213 8.00187 24.3692 8.30775 24.3692Z"
        fill="#C00000"
      />
      <path
        d="M6.09291 26.5846C6.39879 26.5846 6.64675 26.3366 6.64675 26.0308C6.64675 25.7249 6.39879 25.4769 6.09291 25.4769C5.78703 25.4769 5.53906 25.7249 5.53906 26.0308C5.53906 26.3366 5.78703 26.5846 6.09291 26.5846Z"
        fill="#C00000"
      />
      <path
        d="M6.09291 24.3692C6.39879 24.3692 6.64675 24.1213 6.64675 23.8154C6.64675 23.5095 6.39879 23.2615 6.09291 23.2615C5.78703 23.2615 5.53906 23.5095 5.53906 23.8154C5.53906 24.1213 5.78703 24.3692 6.09291 24.3692Z"
        fill="#C00000"
      />
      <path
        d="M7.20033 27.6923C7.50621 27.6923 7.75418 27.4443 7.75418 27.1385C7.75418 26.8326 7.50621 26.5846 7.20033 26.5846C6.89445 26.5846 6.64648 26.8326 6.64648 27.1385C6.64648 27.4443 6.89445 27.6923 7.20033 27.6923Z"
        fill="#C00000"
      />
      <path
        d="M4.98353 27.6923C5.28941 27.6923 5.53738 27.4443 5.53738 27.1385C5.53738 26.8326 5.28941 26.5846 4.98353 26.5846C4.67765 26.5846 4.42969 26.8326 4.42969 27.1385C4.42969 27.4443 4.67765 27.6923 4.98353 27.6923Z"
        fill="#C00000"
      />
      <path
        d="M2.76869 27.6923C3.07457 27.6923 3.32254 27.4443 3.32254 27.1385C3.32254 26.8326 3.07457 26.5846 2.76869 26.5846C2.46281 26.5846 2.21484 26.8326 2.21484 27.1385C2.21484 27.4443 2.46281 27.6923 2.76869 27.6923Z"
        fill="#C00000"
      />
      <path
        d="M1.66127 26.5846C1.96715 26.5846 2.21511 26.3366 2.21511 26.0308C2.21511 25.7249 1.96715 25.4769 1.66127 25.4769C1.35539 25.4769 1.10742 25.7249 1.10742 26.0308C1.10742 26.3366 1.35539 26.5846 1.66127 26.5846Z"
        fill="#C00000"
      />
      <path
        d="M11.63 29.9077C11.9359 29.9077 12.1839 29.6597 12.1839 29.3538C12.1839 29.048 11.9359 28.8 11.63 28.8C11.3241 28.8 11.0762 29.048 11.0762 29.3538C11.0762 29.6597 11.3241 29.9077 11.63 29.9077Z"
        fill="#C00000"
      />
      <path
        d="M10.5226 28.8C10.8285 28.8 11.0764 28.552 11.0764 28.2462C11.0764 27.9403 10.8285 27.6923 10.5226 27.6923C10.2167 27.6923 9.96875 27.9403 9.96875 28.2462C9.96875 28.552 10.2167 28.8 10.5226 28.8Z"
        fill="#C00000"
      />
      <path
        d="M8.30775 28.8C8.61363 28.8 8.8616 28.552 8.8616 28.2462C8.8616 27.9403 8.61363 27.6923 8.30775 27.6923C8.00187 27.6923 7.75391 27.9403 7.75391 28.2462C7.75391 28.552 8.00187 28.8 8.30775 28.8Z"
        fill="#C00000"
      />
      <path
        d="M6.09291 28.8C6.39879 28.8 6.64675 28.552 6.64675 28.2462C6.64675 27.9403 6.39879 27.6923 6.09291 27.6923C5.78703 27.6923 5.53906 27.9403 5.53906 28.2462C5.53906 28.552 5.78703 28.8 6.09291 28.8Z"
        fill="#C00000"
      />
      <path
        d="M3.87611 28.8C4.18199 28.8 4.42996 28.552 4.42996 28.2462C4.42996 27.9403 4.18199 27.6923 3.87611 27.6923C3.57023 27.6923 3.32227 27.9403 3.32227 28.2462C3.32227 28.552 3.57023 28.8 3.87611 28.8Z"
        fill="#C00000"
      />
      <path
        d="M14.9542 24.3692C15.2601 24.3692 15.5081 24.1213 15.5081 23.8154C15.5081 23.5095 15.2601 23.2615 14.9542 23.2615C14.6484 23.2615 14.4004 23.5095 14.4004 23.8154C14.4004 24.1213 14.6484 24.3692 14.9542 24.3692Z"
        fill="#C00000"
      />
      <path
        d="M17.1691 24.3692C17.475 24.3692 17.7229 24.1213 17.7229 23.8154C17.7229 23.5095 17.475 23.2615 17.1691 23.2615C16.8632 23.2615 16.6152 23.5095 16.6152 23.8154C16.6152 24.1213 16.8632 24.3692 17.1691 24.3692Z"
        fill="#C00000"
      />
      <path
        d="M9.41517 29.9077C9.72106 29.9077 9.96902 29.6597 9.96902 29.3538C9.96902 29.048 9.72106 28.8 9.41517 28.8C9.10929 28.8 8.86133 29.048 8.86133 29.3538C8.86133 29.6597 9.10929 29.9077 9.41517 29.9077Z"
        fill="#C00000"
      />
      <path
        d="M7.20033 29.9077C7.50621 29.9077 7.75418 29.6597 7.75418 29.3538C7.75418 29.048 7.50621 28.8 7.20033 28.8C6.89445 28.8 6.64648 29.048 6.64648 29.3538C6.64648 29.6597 6.89445 29.9077 7.20033 29.9077Z"
        fill="#C00000"
      />
      <path
        d="M4.98353 29.9077C5.28941 29.9077 5.53738 29.6597 5.53738 29.3538C5.53738 29.048 5.28941 28.8 4.98353 28.8C4.67765 28.8 4.42969 29.048 4.42969 29.3538C4.42969 29.6597 4.67765 29.9077 4.98353 29.9077Z"
        fill="#C00000"
      />
      <path
        d="M4.98353 25.4769C5.28941 25.4769 5.53738 25.229 5.53738 24.9231C5.53738 24.6172 5.28941 24.3692 4.98353 24.3692C4.67765 24.3692 4.42969 24.6172 4.42969 24.9231C4.42969 25.229 4.67765 25.4769 4.98353 25.4769Z"
        fill="#C00000"
      />
      <path
        d="M3.87611 24.3692C4.18199 24.3692 4.42996 24.1213 4.42996 23.8154C4.42996 23.5095 4.18199 23.2615 3.87611 23.2615C3.57023 23.2615 3.32227 23.5095 3.32227 23.8154C3.32227 24.1213 3.57023 24.3692 3.87611 24.3692Z"
        fill="#C00000"
      />
      <path
        d="M3.87611 26.5846C4.18199 26.5846 4.42996 26.3366 4.42996 26.0308C4.42996 25.7249 4.18199 25.4769 3.87611 25.4769C3.57023 25.4769 3.32227 25.7249 3.32227 26.0308C3.32227 26.3366 3.57023 26.5846 3.87611 26.5846Z"
        fill="#C00000"
      />
      <path
        d="M13.8468 25.4769C14.1527 25.4769 14.4007 25.229 14.4007 24.9231C14.4007 24.6172 14.1527 24.3692 13.8468 24.3692C13.5409 24.3692 13.293 24.6172 13.293 24.9231C13.293 25.229 13.5409 25.4769 13.8468 25.4769Z"
        fill="#C00000"
      />
      <path
        d="M16.0617 25.4769C16.3675 25.4769 16.6155 25.229 16.6155 24.9231C16.6155 24.6172 16.3675 24.3692 16.0617 24.3692C15.7558 24.3692 15.5078 24.6172 15.5078 24.9231C15.5078 25.229 15.7558 25.4769 16.0617 25.4769Z"
        fill="#C00000"
      />
      <path
        d="M18.2765 25.4769C18.5824 25.4769 18.8303 25.229 18.8303 24.9231C18.8303 24.6172 18.5824 24.3692 18.2765 24.3692C17.9706 24.3692 17.7227 24.6172 17.7227 24.9231C17.7227 25.229 17.9706 25.4769 18.2765 25.4769Z"
        fill="#C00000"
      />
      <path
        d="M19.3839 24.3692C19.6898 24.3692 19.9378 24.1213 19.9378 23.8154C19.9378 23.5095 19.6898 23.2615 19.3839 23.2615C19.078 23.2615 18.8301 23.5095 18.8301 23.8154C18.8301 24.1213 19.078 24.3692 19.3839 24.3692Z"
        fill="#C00000"
      />
      <path
        d="M20.4913 25.4769C20.7972 25.4769 21.0452 25.229 21.0452 24.9231C21.0452 24.6172 20.7972 24.3692 20.4913 24.3692C20.1855 24.3692 19.9375 24.6172 19.9375 24.9231C19.9375 25.229 20.1855 25.4769 20.4913 25.4769Z"
        fill="#C00000"
      />
      <path
        d="M20.4913 23.2615C20.7972 23.2615 21.0452 23.0136 21.0452 22.7077C21.0452 22.4018 20.7972 22.1538 20.4913 22.1538C20.1855 22.1538 19.9375 22.4018 19.9375 22.7077C19.9375 23.0136 20.1855 23.2615 20.4913 23.2615Z"
        fill="#C00000"
      />
      <path
        d="M19.3839 26.5846C19.6898 26.5846 19.9378 26.3366 19.9378 26.0308C19.9378 25.7249 19.6898 25.4769 19.3839 25.4769C19.078 25.4769 18.8301 25.7249 18.8301 26.0308C18.8301 26.3366 19.078 26.5846 19.3839 26.5846Z"
        fill="#C00000"
      />
      <path
        d="M20.4913 27.6923C20.7972 27.6923 21.0452 27.4443 21.0452 27.1385C21.0452 26.8326 20.7972 26.5846 20.4913 26.5846C20.1855 26.5846 19.9375 26.8326 19.9375 27.1385C19.9375 27.4443 20.1855 27.6923 20.4913 27.6923Z"
        fill="#C00000"
      />
      <path
        d="M20.4913 29.9077C20.7972 29.9077 21.0452 29.6597 21.0452 29.3538C21.0452 29.048 20.7972 28.8 20.4913 28.8C20.1855 28.8 19.9375 29.048 19.9375 29.3538C19.9375 29.6597 20.1855 29.9077 20.4913 29.9077Z"
        fill="#C00000"
      />
      <path
        d="M20.4913 32.1231C20.7972 32.1231 21.0452 31.8751 21.0452 31.5692C21.0452 31.2633 20.7972 31.0154 20.4913 31.0154C20.1855 31.0154 19.9375 31.2633 19.9375 31.5692C19.9375 31.8751 20.1855 32.1231 20.4913 32.1231Z"
        fill="#C00000"
      />
      <path
        d="M19.3839 33.2308C19.6898 33.2308 19.9378 32.9828 19.9378 32.6769C19.9378 32.371 19.6898 32.1231 19.3839 32.1231C19.078 32.1231 18.8301 32.371 18.8301 32.6769C18.8301 32.9828 19.078 33.2308 19.3839 33.2308Z"
        fill="#C00000"
      />
      <path
        d="M17.1691 33.2308C17.475 33.2308 17.7229 32.9828 17.7229 32.6769C17.7229 32.371 17.475 32.1231 17.1691 32.1231C16.8632 32.1231 16.6152 32.371 16.6152 32.6769C16.6152 32.9828 16.8632 33.2308 17.1691 33.2308Z"
        fill="#C00000"
      />
      <path
        d="M14.9542 33.2308C15.2601 33.2308 15.5081 32.9828 15.5081 32.6769C15.5081 32.371 15.2601 32.1231 14.9542 32.1231C14.6484 32.1231 14.4004 32.371 14.4004 32.6769C14.4004 32.9828 14.6484 33.2308 14.9542 33.2308Z"
        fill="#C00000"
      />
      <path
        d="M12.7374 33.2308C13.0433 33.2308 13.2913 32.9828 13.2913 32.6769C13.2913 32.371 13.0433 32.1231 12.7374 32.1231C12.4316 32.1231 12.1836 32.371 12.1836 32.6769C12.1836 32.9828 12.4316 33.2308 12.7374 33.2308Z"
        fill="#C00000"
      />
      <path
        d="M10.5226 33.2308C10.8285 33.2308 11.0764 32.9828 11.0764 32.6769C11.0764 32.371 10.8285 32.1231 10.5226 32.1231C10.2167 32.1231 9.96875 32.371 9.96875 32.6769C9.96875 32.9828 10.2167 33.2308 10.5226 33.2308Z"
        fill="#C00000"
      />
      <path
        d="M8.30775 33.2308C8.61363 33.2308 8.8616 32.9828 8.8616 32.6769C8.8616 32.371 8.61363 32.1231 8.30775 32.1231C8.00187 32.1231 7.75391 32.371 7.75391 32.6769C7.75391 32.9828 8.00187 33.2308 8.30775 33.2308Z"
        fill="#C00000"
      />
      <path
        d="M6.09291 33.2308C6.39879 33.2308 6.64675 32.9828 6.64675 32.6769C6.64675 32.371 6.39879 32.1231 6.09291 32.1231C5.78703 32.1231 5.53906 32.371 5.53906 32.6769C5.53906 32.9828 5.78703 33.2308 6.09291 33.2308Z"
        fill="#C00000"
      />
      <path
        d="M12.7374 26.5846C13.0433 26.5846 13.2913 26.3366 13.2913 26.0308C13.2913 25.7249 13.0433 25.4769 12.7374 25.4769C12.4316 25.4769 12.1836 25.7249 12.1836 26.0308C12.1836 26.3366 12.4316 26.5846 12.7374 26.5846Z"
        fill="#C00000"
      />
      <path
        d="M14.9542 26.5846C15.2601 26.5846 15.5081 26.3366 15.5081 26.0308C15.5081 25.7249 15.2601 25.4769 14.9542 25.4769C14.6484 25.4769 14.4004 25.7249 14.4004 26.0308C14.4004 26.3366 14.6484 26.5846 14.9542 26.5846Z"
        fill="#C00000"
      />
      <path
        d="M17.1691 26.5846C17.475 26.5846 17.7229 26.3366 17.7229 26.0308C17.7229 25.7249 17.475 25.4769 17.1691 25.4769C16.8632 25.4769 16.6152 25.7249 16.6152 26.0308C16.6152 26.3366 16.8632 26.5846 17.1691 26.5846Z"
        fill="#C00000"
      />
      <path
        d="M13.8468 27.6923C14.1527 27.6923 14.4007 27.4443 14.4007 27.1385C14.4007 26.8326 14.1527 26.5846 13.8468 26.5846C13.5409 26.5846 13.293 26.8326 13.293 27.1385C13.293 27.4443 13.5409 27.6923 13.8468 27.6923Z"
        fill="#C00000"
      />
      <path
        d="M16.0617 27.6923C16.3675 27.6923 16.6155 27.4443 16.6155 27.1385C16.6155 26.8326 16.3675 26.5846 16.0617 26.5846C15.7558 26.5846 15.5078 26.8326 15.5078 27.1385C15.5078 27.4443 15.7558 27.6923 16.0617 27.6923Z"
        fill="#C00000"
      />
      <path
        d="M18.2765 27.6923C18.5824 27.6923 18.8303 27.4443 18.8303 27.1385C18.8303 26.8326 18.5824 26.5846 18.2765 26.5846C17.9706 26.5846 17.7227 26.8326 17.7227 27.1385C17.7227 27.4443 17.9706 27.6923 18.2765 27.6923Z"
        fill="#C00000"
      />
      <path
        d="M12.7374 28.8C13.0433 28.8 13.2913 28.552 13.2913 28.2462C13.2913 27.9403 13.0433 27.6923 12.7374 27.6923C12.4316 27.6923 12.1836 27.9403 12.1836 28.2462C12.1836 28.552 12.4316 28.8 12.7374 28.8Z"
        fill="#C00000"
      />
      <path
        d="M14.9542 28.8C15.2601 28.8 15.5081 28.552 15.5081 28.2462C15.5081 27.9403 15.2601 27.6923 14.9542 27.6923C14.6484 27.6923 14.4004 27.9403 14.4004 28.2462C14.4004 28.552 14.6484 28.8 14.9542 28.8Z"
        fill="#C00000"
      />
      <path
        d="M17.1691 28.8C17.475 28.8 17.7229 28.552 17.7229 28.2462C17.7229 27.9403 17.475 27.6923 17.1691 27.6923C16.8632 27.6923 16.6152 27.9403 16.6152 28.2462C16.6152 28.552 16.8632 28.8 17.1691 28.8Z"
        fill="#C00000"
      />
      <path
        d="M19.3839 28.8C19.6898 28.8 19.9378 28.552 19.9378 28.2462C19.9378 27.9403 19.6898 27.6923 19.3839 27.6923C19.078 27.6923 18.8301 27.9403 18.8301 28.2462C18.8301 28.552 19.078 28.8 19.3839 28.8Z"
        fill="#C00000"
      />
      <path
        d="M10.5226 31.0154C10.8285 31.0154 11.0764 30.7674 11.0764 30.4615C11.0764 30.1557 10.8285 29.9077 10.5226 29.9077C10.2167 29.9077 9.96875 30.1557 9.96875 30.4615C9.96875 30.7674 10.2167 31.0154 10.5226 31.0154Z"
        fill="#C00000"
      />
      <path
        d="M8.30775 31.0154C8.61363 31.0154 8.8616 30.7674 8.8616 30.4615C8.8616 30.1557 8.61363 29.9077 8.30775 29.9077C8.00187 29.9077 7.75391 30.1557 7.75391 30.4615C7.75391 30.7674 8.00187 31.0154 8.30775 31.0154Z"
        fill="#C00000"
      />
      <path
        d="M6.09291 31.0154C6.39879 31.0154 6.64675 30.7674 6.64675 30.4615C6.64675 30.1557 6.39879 29.9077 6.09291 29.9077C5.78703 29.9077 5.53906 30.1557 5.53906 30.4615C5.53906 30.7674 5.78703 31.0154 6.09291 31.0154Z"
        fill="#C00000"
      />
      <path
        d="M3.87611 31.0154C4.18199 31.0154 4.42996 30.7674 4.42996 30.4615C4.42996 30.1557 4.18199 29.9077 3.87611 29.9077C3.57023 29.9077 3.32227 30.1557 3.32227 30.4615C3.32227 30.7674 3.57023 31.0154 3.87611 31.0154Z"
        fill="#C00000"
      />
      <path
        d="M12.7374 31.0154C13.0433 31.0154 13.2913 30.7674 13.2913 30.4615C13.2913 30.1557 13.0433 29.9077 12.7374 29.9077C12.4316 29.9077 12.1836 30.1557 12.1836 30.4615C12.1836 30.7674 12.4316 31.0154 12.7374 31.0154Z"
        fill="#C00000"
      />
      <path
        d="M14.9542 31.0154C15.2601 31.0154 15.5081 30.7674 15.5081 30.4615C15.5081 30.1557 15.2601 29.9077 14.9542 29.9077C14.6484 29.9077 14.4004 30.1557 14.4004 30.4615C14.4004 30.7674 14.6484 31.0154 14.9542 31.0154Z"
        fill="#C00000"
      />
      <path
        d="M17.1691 31.0154C17.475 31.0154 17.7229 30.7674 17.7229 30.4615C17.7229 30.1557 17.475 29.9077 17.1691 29.9077C16.8632 29.9077 16.6152 30.1557 16.6152 30.4615C16.6152 30.7674 16.8632 31.0154 17.1691 31.0154Z"
        fill="#C00000"
      />
      <path
        d="M19.3839 31.0154C19.6898 31.0154 19.9378 30.7674 19.9378 30.4615C19.9378 30.1557 19.6898 29.9077 19.3839 29.9077C19.078 29.9077 18.8301 30.1557 18.8301 30.4615C18.8301 30.7674 19.078 31.0154 19.3839 31.0154Z"
        fill="#C00000"
      />
      <path
        d="M13.8468 29.9077C14.1527 29.9077 14.4007 29.6597 14.4007 29.3538C14.4007 29.048 14.1527 28.8 13.8468 28.8C13.5409 28.8 13.293 29.048 13.293 29.3538C13.293 29.6597 13.5409 29.9077 13.8468 29.9077Z"
        fill="#C00000"
      />
      <path
        d="M16.0617 29.9077C16.3675 29.9077 16.6155 29.6597 16.6155 29.3538C16.6155 29.048 16.3675 28.8 16.0617 28.8C15.7558 28.8 15.5078 29.048 15.5078 29.3538C15.5078 29.6597 15.7558 29.9077 16.0617 29.9077Z"
        fill="#C00000"
      />
      <path
        d="M18.2765 29.9077C18.5824 29.9077 18.8303 29.6597 18.8303 29.3538C18.8303 29.048 18.5824 28.8 18.2765 28.8C17.9706 28.8 17.7227 29.048 17.7227 29.3538C17.7227 29.6597 17.9706 29.9077 18.2765 29.9077Z"
        fill="#C00000"
      />
      <path
        d="M11.63 32.1231C11.9359 32.1231 12.1839 31.8751 12.1839 31.5692C12.1839 31.2633 11.9359 31.0154 11.63 31.0154C11.3241 31.0154 11.0762 31.2633 11.0762 31.5692C11.0762 31.8751 11.3241 32.1231 11.63 32.1231Z"
        fill="#C00000"
      />
      <path
        d="M9.41517 32.1231C9.72106 32.1231 9.96902 31.8751 9.96902 31.5692C9.96902 31.2633 9.72106 31.0154 9.41517 31.0154C9.10929 31.0154 8.86133 31.2633 8.86133 31.5692C8.86133 31.8751 9.10929 32.1231 9.41517 32.1231Z"
        fill="#C00000"
      />
      <path
        d="M7.20033 32.1231C7.50621 32.1231 7.75418 31.8751 7.75418 31.5692C7.75418 31.2633 7.50621 31.0154 7.20033 31.0154C6.89445 31.0154 6.64648 31.2633 6.64648 31.5692C6.64648 31.8751 6.89445 32.1231 7.20033 32.1231Z"
        fill="#C00000"
      />
      <path
        d="M4.98353 32.1231C5.28941 32.1231 5.53738 31.8751 5.53738 31.5692C5.53738 31.2633 5.28941 31.0154 4.98353 31.0154C4.67765 31.0154 4.42969 31.2633 4.42969 31.5692C4.42969 31.8751 4.67765 32.1231 4.98353 32.1231Z"
        fill="#C00000"
      />
      <path
        d="M13.8468 32.1231C14.1527 32.1231 14.4007 31.8751 14.4007 31.5692C14.4007 31.2633 14.1527 31.0154 13.8468 31.0154C13.5409 31.0154 13.293 31.2633 13.293 31.5692C13.293 31.8751 13.5409 32.1231 13.8468 32.1231Z"
        fill="#C00000"
      />
      <path
        d="M16.0617 32.1231C16.3675 32.1231 16.6155 31.8751 16.6155 31.5692C16.6155 31.2633 16.3675 31.0154 16.0617 31.0154C15.7558 31.0154 15.5078 31.2633 15.5078 31.5692C15.5078 31.8751 15.7558 32.1231 16.0617 32.1231Z"
        fill="#C00000"
      />
      <path
        d="M18.2765 32.1231C18.5824 32.1231 18.8303 31.8751 18.8303 31.5692C18.8303 31.2633 18.5824 31.0154 18.2765 31.0154C17.9706 31.0154 17.7227 31.2633 17.7227 31.5692C17.7227 31.8751 17.9706 32.1231 18.2765 32.1231Z"
        fill="#C00000"
      />
      <path
        d="M1.66127 24.3692C1.96715 24.3692 2.21511 24.1213 2.21511 23.8154C2.21511 23.5095 1.96715 23.2615 1.66127 23.2615C1.35539 23.2615 1.10742 23.5095 1.10742 23.8154C1.10742 24.1213 1.35539 24.3692 1.66127 24.3692Z"
        fill="#C00000"
      />
      <path
        d="M12.7374 24.3692C13.0433 24.3692 13.2913 24.1213 13.2913 23.8154C13.2913 23.5095 13.0433 23.2615 12.7374 23.2615C12.4316 23.2615 12.1836 23.5095 12.1836 23.8154C12.1836 24.1213 12.4316 24.3692 12.7374 24.3692Z"
        fill="#C00000"
      />
      <path
        d="M2.76869 25.4769C3.07457 25.4769 3.32254 25.229 3.32254 24.9231C3.32254 24.6172 3.07457 24.3692 2.76869 24.3692C2.46281 24.3692 2.21484 24.6172 2.21484 24.9231C2.21484 25.229 2.46281 25.4769 2.76869 25.4769Z"
        fill="#C00000"
      />
      <path
        d="M10.5226 37.6615C10.8285 37.6615 11.0764 37.4136 11.0764 37.1077C11.0764 36.8018 10.8285 36.5538 10.5226 36.5538C10.2167 36.5538 9.96875 36.8018 9.96875 37.1077C9.96875 37.4136 10.2167 37.6615 10.5226 37.6615Z"
        fill="#C00000"
      />
      <path
        d="M8.30775 35.4462C8.61363 35.4462 8.8616 35.1982 8.8616 34.8923C8.8616 34.5864 8.61363 34.3385 8.30775 34.3385C8.00187 34.3385 7.75391 34.5864 7.75391 34.8923C7.75391 35.1982 8.00187 35.4462 8.30775 35.4462Z"
        fill="#C00000"
      />
      <path
        d="M10.5226 35.4462C10.8285 35.4462 11.0764 35.1982 11.0764 34.8923C11.0764 34.5864 10.8285 34.3385 10.5226 34.3385C10.2167 34.3385 9.96875 34.5864 9.96875 34.8923C9.96875 35.1982 10.2167 35.4462 10.5226 35.4462Z"
        fill="#C00000"
      />
      <path
        d="M9.41517 36.5538C9.72106 36.5538 9.96902 36.3059 9.96902 36C9.96902 35.6941 9.72106 35.4462 9.41517 35.4462C9.10929 35.4462 8.86133 35.6941 8.86133 36C8.86133 36.3059 9.10929 36.5538 9.41517 36.5538Z"
        fill="#C00000"
      />
      <path
        d="M9.41517 34.3385C9.72106 34.3385 9.96902 34.0905 9.96902 33.7846C9.96902 33.4787 9.72106 33.2308 9.41517 33.2308C9.10929 33.2308 8.86133 33.4787 8.86133 33.7846C8.86133 34.0905 9.10929 34.3385 9.41517 34.3385Z"
        fill="#C00000"
      />
      <path
        d="M7.20033 36.5538C7.50621 36.5538 7.75418 36.3059 7.75418 36C7.75418 35.6941 7.50621 35.4462 7.20033 35.4462C6.89445 35.4462 6.64648 35.6941 6.64648 36C6.64648 36.3059 6.89445 36.5538 7.20033 36.5538Z"
        fill="#C00000"
      />
      <path
        d="M8.30775 37.6615C8.61363 37.6615 8.8616 37.4136 8.8616 37.1077C8.8616 36.8018 8.61363 36.5538 8.30775 36.5538C8.00187 36.5538 7.75391 36.8018 7.75391 37.1077C7.75391 37.4136 8.00187 37.6615 8.30775 37.6615Z"
        fill="#C00000"
      />
      <path
        d="M6.09291 35.4462C6.39879 35.4462 6.64675 35.1982 6.64675 34.8923C6.64675 34.5864 6.39879 34.3385 6.09291 34.3385C5.78703 34.3385 5.53906 34.5864 5.53906 34.8923C5.53906 35.1982 5.78703 35.4462 6.09291 35.4462Z"
        fill="#C00000"
      />
      <path
        d="M7.20033 34.3385C7.50621 34.3385 7.75418 34.0905 7.75418 33.7846C7.75418 33.4787 7.50621 33.2308 7.20033 33.2308C6.89445 33.2308 6.64648 33.4787 6.64648 33.7846C6.64648 34.0905 6.89445 34.3385 7.20033 34.3385Z"
        fill="#C00000"
      />
      <path
        d="M4.98549 36.5538C5.29137 36.5538 5.53933 36.3059 5.53933 36C5.53933 35.6941 5.29137 35.4462 4.98549 35.4462C4.67961 35.4462 4.43164 35.6941 4.43164 36C4.43164 36.3059 4.67961 36.5538 4.98549 36.5538Z"
        fill="#C00000"
      />
      <path
        d="M3.55385 33.1077C3.85973 33.1077 4.10769 32.8597 4.10769 32.5538C4.10769 32.248 3.85973 32 3.55385 32C3.24797 32 3 32.248 3 32.5538C3 32.8597 3.24797 33.1077 3.55385 33.1077Z"
        fill="#C00000"
      />
      <path
        d="M6.09291 37.6615C6.39879 37.6615 6.64675 37.4136 6.64675 37.1077C6.64675 36.8018 6.39879 36.5538 6.09291 36.5538C5.78703 36.5538 5.53906 36.8018 5.53906 37.1077C5.53906 37.4136 5.78703 37.6615 6.09291 37.6615Z"
        fill="#C00000"
      />
      <path
        d="M3.87611 37.6615C4.18199 37.6615 4.42996 37.4136 4.42996 37.1077C4.42996 36.8018 4.18199 36.5538 3.87611 36.5538C3.57023 36.5538 3.32227 36.8018 3.32227 37.1077C3.32227 37.4136 3.57023 37.6615 3.87611 37.6615Z"
        fill="#C00000"
      />
      <path
        d="M1.66127 37.6615C1.96715 37.6615 2.21511 37.4136 2.21511 37.1077C2.21511 36.8018 1.96715 36.5538 1.66127 36.5538C1.35539 36.5538 1.10742 36.8018 1.10742 37.1077C1.10742 37.4136 1.35539 37.6615 1.66127 37.6615Z"
        fill="#C00000"
      />
      <path
        d="M0.553846 36.5538C0.859727 36.5538 1.10769 36.3059 1.10769 36C1.10769 35.6941 0.859727 35.4462 0.553846 35.4462C0.247965 35.4462 0 35.6941 0 36C0 36.3059 0.247965 36.5538 0.553846 36.5538Z"
        fill="#C00000"
      />
      <path
        d="M10.5226 39.8769C10.8285 39.8769 11.0764 39.629 11.0764 39.3231C11.0764 39.0172 10.8285 38.7692 10.5226 38.7692C10.2167 38.7692 9.96875 39.0172 9.96875 39.3231C9.96875 39.629 10.2167 39.8769 10.5226 39.8769Z"
        fill="#C00000"
      />
      <path
        d="M9.41517 38.7692C9.72106 38.7692 9.96902 38.5213 9.96902 38.2154C9.96902 37.9095 9.72106 37.6615 9.41517 37.6615C9.10929 37.6615 8.86133 37.9095 8.86133 38.2154C8.86133 38.5213 9.10929 38.7692 9.41517 38.7692Z"
        fill="#C00000"
      />
      <path
        d="M7.20033 38.7692C7.50621 38.7692 7.75418 38.5213 7.75418 38.2154C7.75418 37.9095 7.50621 37.6615 7.20033 37.6615C6.89445 37.6615 6.64648 37.9095 6.64648 38.2154C6.64648 38.5213 6.89445 38.7692 7.20033 38.7692Z"
        fill="#C00000"
      />
      <path
        d="M4.98549 38.7692C5.29137 38.7692 5.53933 38.5213 5.53933 38.2154C5.53933 37.9095 5.29137 37.6615 4.98549 37.6615C4.67961 37.6615 4.43164 37.9095 4.43164 38.2154C4.43164 38.5213 4.67961 38.7692 4.98549 38.7692Z"
        fill="#C00000"
      />
      <path
        d="M2.76869 38.7692C3.07457 38.7692 3.32254 38.5213 3.32254 38.2154C3.32254 37.9095 3.07457 37.6615 2.76869 37.6615C2.46281 37.6615 2.21484 37.9095 2.21484 38.2154C2.21484 38.5213 2.46281 38.7692 2.76869 38.7692Z"
        fill="#C00000"
      />
      <path
        d="M13.8468 34.3385C14.1527 34.3385 14.4007 34.0905 14.4007 33.7846C14.4007 33.4787 14.1527 33.2308 13.8468 33.2308C13.5409 33.2308 13.293 33.4787 13.293 33.7846C13.293 34.0905 13.5409 34.3385 13.8468 34.3385Z"
        fill="#C00000"
      />
      <path
        d="M16.0617 34.3385C16.3675 34.3385 16.6155 34.0905 16.6155 33.7846C16.6155 33.4787 16.3675 33.2308 16.0617 33.2308C15.7558 33.2308 15.5078 33.4787 15.5078 33.7846C15.5078 34.0905 15.7558 34.3385 16.0617 34.3385Z"
        fill="#C00000"
      />
      <path
        d="M8.30775 39.8769C8.61363 39.8769 8.8616 39.629 8.8616 39.3231C8.8616 39.0172 8.61363 38.7692 8.30775 38.7692C8.00187 38.7692 7.75391 39.0172 7.75391 39.3231C7.75391 39.629 8.00187 39.8769 8.30775 39.8769Z"
        fill="#C00000"
      />
      <path
        d="M6.09291 39.8769C6.39879 39.8769 6.64675 39.629 6.64675 39.3231C6.64675 39.0172 6.39879 38.7692 6.09291 38.7692C5.78703 38.7692 5.53906 39.0172 5.53906 39.3231C5.53906 39.629 5.78703 39.8769 6.09291 39.8769Z"
        fill="#C00000"
      />
      <path
        d="M3.87611 39.8769C4.18199 39.8769 4.42996 39.629 4.42996 39.3231C4.42996 39.0172 4.18199 38.7692 3.87611 38.7692C3.57023 38.7692 3.32227 39.0172 3.32227 39.3231C3.32227 39.629 3.57023 39.8769 3.87611 39.8769Z"
        fill="#C00000"
      />
      <path
        d="M3.87611 35.4462C4.18199 35.4462 4.42996 35.1982 4.42996 34.8923C4.42996 34.5864 4.18199 34.3385 3.87611 34.3385C3.57023 34.3385 3.32227 34.5864 3.32227 34.8923C3.32227 35.1982 3.57023 35.4462 3.87611 35.4462Z"
        fill="#C00000"
      />
      <path
        d="M2.76869 34.3385C3.07457 34.3385 3.32254 34.0905 3.32254 33.7846C3.32254 33.4787 3.07457 33.2308 2.76869 33.2308C2.46281 33.2308 2.21484 33.4787 2.21484 33.7846C2.21484 34.0905 2.46281 34.3385 2.76869 34.3385Z"
        fill="#C00000"
      />
      <path
        d="M2.76869 36.5538C3.07457 36.5538 3.32254 36.3059 3.32254 36C3.32254 35.6941 3.07457 35.4462 2.76869 35.4462C2.46281 35.4462 2.21484 35.6941 2.21484 36C2.21484 36.3059 2.46281 36.5538 2.76869 36.5538Z"
        fill="#C00000"
      />
      <path
        d="M12.7394 35.4462C13.0453 35.4462 13.2932 35.1982 13.2932 34.8923C13.2932 34.5864 13.0453 34.3385 12.7394 34.3385C12.4335 34.3385 12.1855 34.5864 12.1855 34.8923C12.1855 35.1982 12.4335 35.4462 12.7394 35.4462Z"
        fill="#C00000"
      />
      <path
        d="M14.9542 35.4462C15.2601 35.4462 15.5081 35.1982 15.5081 34.8923C15.5081 34.5864 15.2601 34.3385 14.9542 34.3385C14.6484 34.3385 14.4004 34.5864 14.4004 34.8923C14.4004 35.1982 14.6484 35.4462 14.9542 35.4462Z"
        fill="#C00000"
      />
      <path
        d="M17.1691 35.4462C17.475 35.4462 17.7229 35.1982 17.7229 34.8923C17.7229 34.5864 17.475 34.3385 17.1691 34.3385C16.8632 34.3385 16.6152 34.5864 16.6152 34.8923C16.6152 35.1982 16.8632 35.4462 17.1691 35.4462Z"
        fill="#C00000"
      />
      <path
        d="M18.2765 34.3385C18.5824 34.3385 18.8303 34.0905 18.8303 33.7846C18.8303 33.4787 18.5824 33.2308 18.2765 33.2308C17.9706 33.2308 17.7227 33.4787 17.7227 33.7846C17.7227 34.0905 17.9706 34.3385 18.2765 34.3385Z"
        fill="#C00000"
      />
      <path
        d="M19.3839 35.4462C19.6898 35.4462 19.9378 35.1982 19.9378 34.8923C19.9378 34.5864 19.6898 34.3385 19.3839 34.3385C19.078 34.3385 18.8301 34.5864 18.8301 34.8923C18.8301 35.1982 19.078 35.4462 19.3839 35.4462Z"
        fill="#C00000"
      />
      <path
        d="M20.4913 34.3385C20.7972 34.3385 21.0452 34.0905 21.0452 33.7846C21.0452 33.4787 20.7972 33.2308 20.4913 33.2308C20.1855 33.2308 19.9375 33.4787 19.9375 33.7846C19.9375 34.0905 20.1855 34.3385 20.4913 34.3385Z"
        fill="#C00000"
      />
      <path
        d="M18.2765 36.5538C18.5824 36.5538 18.8303 36.3059 18.8303 36C18.8303 35.6941 18.5824 35.4462 18.2765 35.4462C17.9706 35.4462 17.7227 35.6941 17.7227 36C17.7227 36.3059 17.9706 36.5538 18.2765 36.5538Z"
        fill="#C00000"
      />
      <path
        d="M19.3839 37.6615C19.6898 37.6615 19.9378 37.4136 19.9378 37.1077C19.9378 36.8018 19.6898 36.5538 19.3839 36.5538C19.078 36.5538 18.8301 36.8018 18.8301 37.1077C18.8301 37.4136 19.078 37.6615 19.3839 37.6615Z"
        fill="#C00000"
      />
      <path
        d="M20.4913 36.5538C20.7972 36.5538 21.0452 36.3059 21.0452 36C21.0452 35.6941 20.7972 35.4462 20.4913 35.4462C20.1855 35.4462 19.9375 35.6941 19.9375 36C19.9375 36.3059 20.1855 36.5538 20.4913 36.5538Z"
        fill="#C00000"
      />
      <path
        d="M19.3839 39.8769C19.6898 39.8769 19.9378 39.629 19.9378 39.3231C19.9378 39.0172 19.6898 38.7692 19.3839 38.7692C19.078 38.7692 18.8301 39.0172 18.8301 39.3231C18.8301 39.629 19.078 39.8769 19.3839 39.8769Z"
        fill="#C00000"
      />
      <path
        d="M20.4913 40.9846C20.7972 40.9846 21.0452 40.7366 21.0452 40.4308C21.0452 40.1249 20.7972 39.8769 20.4913 39.8769C20.1855 39.8769 19.9375 40.1249 19.9375 40.4308C19.9375 40.7366 20.1855 40.9846 20.4913 40.9846Z"
        fill="#C00000"
      />
      <path
        d="M20.4913 38.7692C20.7972 38.7692 21.0452 38.5213 21.0452 38.2154C21.0452 37.9095 20.7972 37.6615 20.4913 37.6615C20.1855 37.6615 19.9375 37.9095 19.9375 38.2154C19.9375 38.5213 20.1855 38.7692 20.4913 38.7692Z"
        fill="#C00000"
      />
      <path
        d="M19.3839 42.0923C19.6898 42.0923 19.9378 41.8443 19.9378 41.5385C19.9378 41.2326 19.6898 40.9846 19.3839 40.9846C19.078 40.9846 18.8301 41.2326 18.8301 41.5385C18.8301 41.8443 19.078 42.0923 19.3839 42.0923Z"
        fill="#C00000"
      />
      <path
        d="M11.63 36.5538C11.9359 36.5538 12.1839 36.3059 12.1839 36C12.1839 35.6941 11.9359 35.4462 11.63 35.4462C11.3241 35.4462 11.0762 35.6941 11.0762 36C11.0762 36.3059 11.3241 36.5538 11.63 36.5538Z"
        fill="#C00000"
      />
      <path
        d="M13.8468 36.5538C14.1527 36.5538 14.4007 36.3059 14.4007 36C14.4007 35.6941 14.1527 35.4462 13.8468 35.4462C13.5409 35.4462 13.293 35.6941 13.293 36C13.293 36.3059 13.5409 36.5538 13.8468 36.5538Z"
        fill="#C00000"
      />
      <path
        d="M16.0617 36.5538C16.3675 36.5538 16.6155 36.3059 16.6155 36C16.6155 35.6941 16.3675 35.4462 16.0617 35.4462C15.7558 35.4462 15.5078 35.6941 15.5078 36C15.5078 36.3059 15.7558 36.5538 16.0617 36.5538Z"
        fill="#C00000"
      />
      <path
        d="M12.7394 37.6615C13.0453 37.6615 13.2932 37.4136 13.2932 37.1077C13.2932 36.8018 13.0453 36.5538 12.7394 36.5538C12.4335 36.5538 12.1855 36.8018 12.1855 37.1077C12.1855 37.4136 12.4335 37.6615 12.7394 37.6615Z"
        fill="#C00000"
      />
      <path
        d="M14.9542 37.6615C15.2601 37.6615 15.5081 37.4136 15.5081 37.1077C15.5081 36.8018 15.2601 36.5538 14.9542 36.5538C14.6484 36.5538 14.4004 36.8018 14.4004 37.1077C14.4004 37.4136 14.6484 37.6615 14.9542 37.6615Z"
        fill="#C00000"
      />
      <path
        d="M17.1691 37.6615C17.475 37.6615 17.7229 37.4136 17.7229 37.1077C17.7229 36.8018 17.475 36.5538 17.1691 36.5538C16.8632 36.5538 16.6152 36.8018 16.6152 37.1077C16.6152 37.4136 16.8632 37.6615 17.1691 37.6615Z"
        fill="#C00000"
      />
      <path
        d="M11.63 38.7692C11.9359 38.7692 12.1839 38.5213 12.1839 38.2154C12.1839 37.9095 11.9359 37.6615 11.63 37.6615C11.3241 37.6615 11.0762 37.9095 11.0762 38.2154C11.0762 38.5213 11.3241 38.7692 11.63 38.7692Z"
        fill="#C00000"
      />
      <path
        d="M13.8468 38.7692C14.1527 38.7692 14.4007 38.5213 14.4007 38.2154C14.4007 37.9095 14.1527 37.6615 13.8468 37.6615C13.5409 37.6615 13.293 37.9095 13.293 38.2154C13.293 38.5213 13.5409 38.7692 13.8468 38.7692Z"
        fill="#C00000"
      />
      <path
        d="M16.0617 38.7692C16.3675 38.7692 16.6155 38.5213 16.6155 38.2154C16.6155 37.9095 16.3675 37.6615 16.0617 37.6615C15.7558 37.6615 15.5078 37.9095 15.5078 38.2154C15.5078 38.5213 15.7558 38.7692 16.0617 38.7692Z"
        fill="#C00000"
      />
      <path
        d="M18.2765 38.7692C18.5824 38.7692 18.8303 38.5213 18.8303 38.2154C18.8303 37.9095 18.5824 37.6615 18.2765 37.6615C17.9706 37.6615 17.7227 37.9095 17.7227 38.2154C17.7227 38.5213 17.9706 38.7692 18.2765 38.7692Z"
        fill="#C00000"
      />
      <path
        d="M9.41517 40.9846C9.72106 40.9846 9.96902 40.7366 9.96902 40.4308C9.96902 40.1249 9.72106 39.8769 9.41517 39.8769C9.10929 39.8769 8.86133 40.1249 8.86133 40.4308C8.86133 40.7366 9.10929 40.9846 9.41517 40.9846Z"
        fill="#C00000"
      />
      <path
        d="M7.20033 40.9846C7.50621 40.9846 7.75418 40.7366 7.75418 40.4308C7.75418 40.1249 7.50621 39.8769 7.20033 39.8769C6.89445 39.8769 6.64648 40.1249 6.64648 40.4308C6.64648 40.7366 6.89445 40.9846 7.20033 40.9846Z"
        fill="#C00000"
      />
      <path
        d="M4.98549 40.9846C5.29137 40.9846 5.53933 40.7366 5.53933 40.4308C5.53933 40.1249 5.29137 39.8769 4.98549 39.8769C4.67961 39.8769 4.43164 40.1249 4.43164 40.4308C4.43164 40.7366 4.67961 40.9846 4.98549 40.9846Z"
        fill="#C00000"
      />
      <path
        d="M2.76869 40.9846C3.07457 40.9846 3.32254 40.7366 3.32254 40.4308C3.32254 40.1249 3.07457 39.8769 2.76869 39.8769C2.46281 39.8769 2.21484 40.1249 2.21484 40.4308C2.21484 40.7366 2.46281 40.9846 2.76869 40.9846Z"
        fill="#C00000"
      />
      <path
        d="M11.63 40.9846C11.9359 40.9846 12.1839 40.7366 12.1839 40.4308C12.1839 40.1249 11.9359 39.8769 11.63 39.8769C11.3241 39.8769 11.0762 40.1249 11.0762 40.4308C11.0762 40.7366 11.3241 40.9846 11.63 40.9846Z"
        fill="#C00000"
      />
      <path
        d="M13.8468 40.9846C14.1527 40.9846 14.4007 40.7366 14.4007 40.4308C14.4007 40.1249 14.1527 39.8769 13.8468 39.8769C13.5409 39.8769 13.293 40.1249 13.293 40.4308C13.293 40.7366 13.5409 40.9846 13.8468 40.9846Z"
        fill="#C00000"
      />
      <path
        d="M16.0617 40.9846C16.3675 40.9846 16.6155 40.7366 16.6155 40.4308C16.6155 40.1249 16.3675 39.8769 16.0617 39.8769C15.7558 39.8769 15.5078 40.1249 15.5078 40.4308C15.5078 40.7366 15.7558 40.9846 16.0617 40.9846Z"
        fill="#C00000"
      />
      <path
        d="M18.2765 40.9846C18.5824 40.9846 18.8303 40.7366 18.8303 40.4308C18.8303 40.1249 18.5824 39.8769 18.2765 39.8769C17.9706 39.8769 17.7227 40.1249 17.7227 40.4308C17.7227 40.7366 17.9706 40.9846 18.2765 40.9846Z"
        fill="#C00000"
      />
      <path
        d="M12.7394 39.8769C13.0453 39.8769 13.2932 39.629 13.2932 39.3231C13.2932 39.0172 13.0453 38.7692 12.7394 38.7692C12.4335 38.7692 12.1855 39.0172 12.1855 39.3231C12.1855 39.629 12.4335 39.8769 12.7394 39.8769Z"
        fill="#C00000"
      />
      <path
        d="M14.9542 39.8769C15.2601 39.8769 15.5081 39.629 15.5081 39.3231C15.5081 39.0172 15.2601 38.7692 14.9542 38.7692C14.6484 38.7692 14.4004 39.0172 14.4004 39.3231C14.4004 39.629 14.6484 39.8769 14.9542 39.8769Z"
        fill="#C00000"
      />
      <path
        d="M17.1691 39.8769C17.475 39.8769 17.7229 39.629 17.7229 39.3231C17.7229 39.0172 17.475 38.7692 17.1691 38.7692C16.8632 38.7692 16.6152 39.0172 16.6152 39.3231C16.6152 39.629 16.8632 39.8769 17.1691 39.8769Z"
        fill="#C00000"
      />
      <path
        d="M10.5226 42.0923C10.8285 42.0923 11.0764 41.8443 11.0764 41.5385C11.0764 41.2326 10.8285 40.9846 10.5226 40.9846C10.2167 40.9846 9.96875 41.2326 9.96875 41.5385C9.96875 41.8443 10.2167 42.0923 10.5226 42.0923Z"
        fill="#C00000"
      />
      <path
        d="M8.30775 42.0923C8.61363 42.0923 8.8616 41.8443 8.8616 41.5385C8.8616 41.2326 8.61363 40.9846 8.30775 40.9846C8.00187 40.9846 7.75391 41.2326 7.75391 41.5385C7.75391 41.8443 8.00187 42.0923 8.30775 42.0923Z"
        fill="#C00000"
      />
      <path
        d="M6.09291 42.0923C6.39879 42.0923 6.64675 41.8443 6.64675 41.5385C6.64675 41.2326 6.39879 40.9846 6.09291 40.9846C5.78703 40.9846 5.53906 41.2326 5.53906 41.5385C5.53906 41.8443 5.78703 42.0923 6.09291 42.0923Z"
        fill="#C00000"
      />
      <path
        d="M3.87611 42.0923C4.18199 42.0923 4.42996 41.8443 4.42996 41.5385C4.42996 41.2326 4.18199 40.9846 3.87611 40.9846C3.57023 40.9846 3.32227 41.2326 3.32227 41.5385C3.32227 41.8443 3.57023 42.0923 3.87611 42.0923Z"
        fill="#C00000"
      />
      <path
        d="M12.7394 42.0923C13.0453 42.0923 13.2932 41.8443 13.2932 41.5385C13.2932 41.2326 13.0453 40.9846 12.7394 40.9846C12.4335 40.9846 12.1855 41.2326 12.1855 41.5385C12.1855 41.8443 12.4335 42.0923 12.7394 42.0923Z"
        fill="#C00000"
      />
      <path
        d="M14.9542 42.0923C15.2601 42.0923 15.5081 41.8443 15.5081 41.5385C15.5081 41.2326 15.2601 40.9846 14.9542 40.9846C14.6484 40.9846 14.4004 41.2326 14.4004 41.5385C14.4004 41.8443 14.6484 42.0923 14.9542 42.0923Z"
        fill="#C00000"
      />
      <path
        d="M17.1691 42.0923C17.475 42.0923 17.7229 41.8443 17.7229 41.5385C17.7229 41.2326 17.475 40.9846 17.1691 40.9846C16.8632 40.9846 16.6152 41.2326 16.6152 41.5385C16.6152 41.8443 16.8632 42.0923 17.1691 42.0923Z"
        fill="#C00000"
      />
      <path
        d="M0.553846 34.3385C0.859727 34.3385 1.10769 34.0905 1.10769 33.7846C1.10769 33.4787 0.859727 33.2308 0.553846 33.2308C0.247965 33.2308 0 33.4787 0 33.7846C0 34.0905 0.247965 34.3385 0.553846 34.3385Z"
        fill="#C00000"
      />
      <path
        d="M11.63 34.3385C11.9359 34.3385 12.1839 34.0905 12.1839 33.7846C12.1839 33.4787 11.9359 33.2308 11.63 33.2308C11.3241 33.2308 11.0762 33.4787 11.0762 33.7846C11.0762 34.0905 11.3241 34.3385 11.63 34.3385Z"
        fill="#C00000"
      />
      <path
        d="M1.66127 35.4462C1.96715 35.4462 2.21511 35.1982 2.21511 34.8923C2.21511 34.5864 1.96715 34.3385 1.66127 34.3385C1.35539 34.3385 1.10742 34.5864 1.10742 34.8923C1.10742 35.1982 1.35539 35.4462 1.66127 35.4462Z"
        fill="#C00000"
      />
      <path
        d="M0 19.9551V43.236H17.3277L41.5731 63.1911V0L17.3277 19.9551H0ZM37.8315 7.93214V55.2423L19.7056 40.3259C19.0405 39.7771 18.1924 39.4778 17.3277 39.4778H3.74158V23.6967H17.311C18.1758 23.6967 19.0238 23.3973 19.689 22.8486L37.8149 7.93214H37.8315Z"
        fill="black"
      />
      <path
        d="M50.6122 15.8128C50.1717 15.5334 49.6617 15.4403 49.1517 15.5567C48.6417 15.6731 48.2129 15.9757 47.9347 16.4064C47.6565 16.8487 47.5638 17.3608 47.6797 17.8729C47.7956 18.3851 48.0969 18.8157 48.5258 19.0951C53.1738 22.0747 55.9441 27.1495 55.9441 32.6781C55.9441 38.2068 53.1738 43.2816 48.5258 46.2612C48.0853 46.5406 47.784 46.9712 47.6797 47.4834C47.5638 47.9955 47.6565 48.5076 47.9347 48.9499C48.294 49.5086 48.9083 49.8462 49.569 49.8462C49.9399 49.8462 50.2992 49.7414 50.6122 49.5435C56.3845 45.8422 59.8155 39.5337 59.8155 32.6781C59.8155 25.8226 56.3729 19.5141 50.6122 15.8128Z"
        fill="black"
      />
      <path
        d="M60.2083 10.2414C59.8261 9.99525 59.3743 9.9132 58.9342 10.007C58.494 10.1007 58.1118 10.3703 57.8686 10.7571C57.6253 11.1439 57.5442 11.601 57.6369 12.0464C57.7296 12.4918 57.996 12.8786 58.3782 13.1247C64.7951 17.3091 68.6174 24.412 68.6174 32.1244C68.6174 39.8367 64.7951 46.9279 58.3782 51.124C57.996 51.3701 57.7296 51.7569 57.6369 52.2023C57.5442 52.6477 57.6253 53.1048 57.8686 53.4916C58.1813 53.9839 58.7141 54.2769 59.2932 54.2769C59.6176 54.2769 59.9419 54.1832 60.2083 54.0073C67.5981 49.19 71.9996 41.0088 71.9996 32.1244C71.9996 23.2399 67.5981 15.0587 60.2083 10.2414Z"
        fill="black"
      />
    </svg>
  )
}

export default Audio

import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * ChatBubble icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const ChatBubble: React.FC<IconProps> = ({
  color,
  filled,
  ...remainingProps
}) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 24 24" {...remainingProps}>
      <path
        d="M19.489 1H3.844A2.85 2.85 0 0 0 1 3.857v8.572a2.851 2.851 0 0 0 2.844 2.857h8.534L18.067 21v-5.714h1.422c1.57 0 2.844-1.28 2.844-2.857V3.857A2.85 2.85 0 0 0 19.49 1Z"
        fillRule="nonzero"
        stroke={colorString}
        strokeWidth="1.5"
        fill={filled ? colorString : "none"}
      />
    </svg>
  )
}

export default ChatBubble

import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Wrench icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Wrench = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="-2 -2 76 76" {...remainingProps}>
      <g stroke={colorString} fill="none" fillRule="evenodd">
        <path
          d="m7.683 54.445 2.822-2.658.16.163-.16-.163 2.591-2.44c2.143-2.02 4.141-3.903 5.887-5.551l2.593-2.447.267-.251 2.673-2.523a17.995 17.995 0 0 1-1.26-5.026c-.475-4.8.933-9.422 3.96-13.012.31-.37.635-.722.973-1.06 2.815-2.817 6.5-4.503 10.523-4.793 2.724-.194 5.475.315 8.179 1.516l2.393 1.065-9.58 9.392.013 6.195 6.29.073 9.625-9.44.99 2.482.163.44c.518 1.487.747 3.13.9 4.567.525 4.837-.854 9.5-3.884 13.13-2.908 3.493-7.014 5.586-11.552 5.912-2.297.163-4.616-.171-6.91-1l-2.692 2.77-.321.332-2.158 2.224-6.115 6.303-.815.842-1.662 1.716-.836-.863-.295-.304.295.304.836.863-2.38 2.459-.802.828-.803.83.232-.24a36.05 36.05 0 0 1-3.03-1.986l.396-.408c1.315-1.36 2.627-2.716 3.91-4.04l2.477-2.558.545-.563c1.987-2.05 3.839-3.958 5.456-5.624l.384-.396 2.095-2.158.212-.22 3.349-3.446.858-.883 1.128.493.427.179c1.995.802 3.998 1.137 5.965.995a12.923 12.923 0 0 0 9.072-4.642c2.406-2.882 3.5-6.6 3.08-10.468a32.384 32.384 0 0 0-.158-1.276l-6.545 6.42-11.279-.13-.023-11.203 6.727-6.595-.436-.098c-1.162-.24-2.32-.32-3.467-.239a12.937 12.937 0 0 0-9.031 4.597c-2.4 2.85-3.516 6.531-3.136 10.371.162 1.682.653 3.373 1.456 5.022l.57 1.175-.949.897-.011.01c-.15.143-1.532 1.448-3.697 3.49-.769.727-1.644 1.554-2.592 2.447l-.49.461c-1.653 1.56-3.52 3.321-5.511 5.196l-.851.803a13668.933 13668.933 0 0 1-4.016 3.782l-.922.867-.924.87-.675.636a36.13 36.13 0 0 1-2.138-2.877Z"
          strokeWidth=".75"
          fill={colorString}
        />
        <circle strokeWidth="4" cx="36" cy="36" r="36" />
      </g>
    </svg>
  )
}

export default Wrench

import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Notification icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Notification: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="-1 -1 74 74" {...remainingProps}>
      <g stroke={colorString} strokeWidth="4" fill="none" fillRule="evenodd">
        <path
          d="m72 60.25-9.888-16.156V26.957C62.112 12.692 50.422 1 36 1 21.579 1 9.888 12.692 9.888 26.957v17.137L0 60.25h72Z"
          strokeLinejoin="round"
        />
        <path d="M26.593 61.25c-.006.141-.022.28-.022.424C26.57 66.824 30.792 71 36 71c5.207 0 9.429-4.177 9.429-9.326 0-.144-.015-.283-.022-.423H26.593Z" />
      </g>
    </svg>
  )
}

export default Notification

import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Warning icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Warning: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="-2 0 78 76" {...remainingProps}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M71.431 62.313 39.945 5.328c-1.715-3.104-6.176-3.104-7.89 0L.568 62.313c-1.659 3.004.513 6.686 3.945 6.686h62.973c3.432 0 5.606-3.682 3.945-6.686"
          stroke={colorString}
          strokeWidth="4"
        />
        <path
          d="M32.442 22.506h7.02l-1.058 22.939h-4.903l-1.06-22.94Zm.913 34.857a3.782 3.782 0 0 1-1.105-2.73c0-1.06.367-1.97 1.105-2.732.737-.762 1.619-1.141 2.645-1.141 1.057 0 1.948.379 2.668 1.141.722.762 1.082 1.673 1.082 2.732 0 1.059-.36 1.969-1.082 2.73-.72.76-1.611 1.143-2.668 1.143-1.026 0-1.908-.383-2.645-1.143Z"
          fill={colorString}
        />
      </g>
    </svg>
  )
}

export default Warning

import React from "react"
import { IconProps } from "./Icon.d"

/**
 * Spanish icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */

const Spanish: React.FC<IconProps> = ({ ...remainingProps }) => {
  return (
    <svg viewBox="0 0 76 76" fill="none" {...remainingProps}>
      <rect
        x="2"
        y="2"
        width="72"
        height="72"
        rx="10"
        stroke="#444445"
        strokeWidth="4"
      />
      <path
        d="M17 24H34V28.76H22.4V35.88H33.64V40.48H22.4V47.64H34.32V52H17V24Z"
        fill="#444445"
      />
      <path
        d="M49.9071 52C48.1784 52 46.4895 51.7699 44.8406 51.3096C43.1917 50.8237 41.7422 50.1589 40.4922 49.3151L41.8885 44.5205C43.0587 45.3644 44.3752 46.0548 45.8379 46.5918C47.3007 47.1288 48.7502 47.3973 50.1863 47.3973C51.5959 47.3973 52.673 47.116 53.4177 46.5534C54.1624 45.9909 54.5347 45.1854 54.5347 44.137C54.5347 43.4466 54.3353 42.884 53.9363 42.4493C53.564 42.0146 53.0188 41.6438 52.3007 41.337C51.6092 41.0046 50.5055 40.5699 48.9895 40.0329C47.2608 39.4192 45.8379 38.7799 44.7209 38.1151C43.6305 37.4502 42.7528 36.632 42.0879 35.6603C41.4496 34.663 41.1305 33.4484 41.1305 32.0164C41.1305 29.5361 42.0214 27.5799 43.8034 26.1479C45.6119 24.716 47.9789 24 50.9044 24C52.3938 24 53.8831 24.179 55.3725 24.537C56.8619 24.895 58.1119 25.3425 59.1225 25.8795L57.6065 30.3288C56.6491 29.8174 55.5587 29.3954 54.3353 29.063C53.1119 28.7306 51.9683 28.5644 50.9044 28.5644C49.6012 28.5644 48.5507 28.8329 47.7528 29.3699C46.9816 29.8813 46.5959 30.5973 46.5959 31.5178C46.5959 32.2082 46.8087 32.7963 47.2342 33.2822C47.6863 33.7425 48.2847 34.1388 49.0294 34.4712C49.7741 34.8037 50.8379 35.2128 52.2209 35.6986C54.0294 36.3379 55.4523 36.9388 56.4895 37.5014C57.5534 38.0639 58.4044 38.831 59.0427 39.8027C59.681 40.7489 60.0002 41.989 60.0002 43.5233C60.0002 46.1826 59.0826 48.2667 57.2475 49.7753C55.439 51.2584 52.9922 52 49.9071 52Z"
        fill="#444445"
      />
    </svg>
  )
}

export default Spanish

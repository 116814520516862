import React from "react"
import { IconProps } from "./Icon.d"

const XSeries: React.FC<IconProps> = ({ ...remainingProps }) => {
  return (
    <svg viewBox="0 0 189 41" fill="none" {...remainingProps}>
      <g clipPath="url(#clip0_2419_16404)">
        <path
          d="M20.225 35.6452L8.51001 38.5074"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.24 23.0875L20.225 35.6452L20.455 38.5074"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.25 33.4174L48 38.5075"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.24 23.0875L50.25 33.4174L60.31 38.5074"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M86.665 2.49255L73.92 5.53958"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M73.35 2.49255L73.92 5.53958"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.37 12.7975L36.72 8.01221"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.37 12.7975L24.42 8.01221"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.37 12.7975L42.24 23.0874"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M73.92 5.53961L42.24 23.0875"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.29 23.6419L42.24 23.0875H48.525"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.8 27.9677L42.2401 23.0875L43.66 17.9075"
          stroke="#020303"
          strokeWidth="1.12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask
          id="mask0_2419_16404"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="189"
          height="41"
        >
          <path d="M189 0H0V40.97H189V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2419_16404)">
          <path
            d="M46.6101 40.6903C46.7801 40.9101 46.9601 41.005 47.2401 41H63.6051C64.0901 41 64.4451 40.5654 64.4101 40.0959C64.3401 39.1518 65.0501 38.2877 66.0251 38.2877H188C188.57 38.2877 189.005 37.8031 189.005 37.2437V17.7827C189.005 17.2282 188.575 16.7387 188 16.7437H72.6451C70.9251 16.7437 70.4051 14.356 71.9151 13.5618C75.5251 11.6686 79.1351 9.77548 82.7501 7.88233C86.7501 5.78437 90.7551 3.68642 94.7551 1.58847C95.5101 1.19385 95.2501 -0.00497956 94.3901 1.5563e-05H72.9751C72.8451 1.5563e-05 72.7301 0.0299863 72.6151 0.0899279C67.6301 2.6774 62.6401 5.26987 57.6551 7.85735C53.2401 10.1501 48.8251 12.4429 44.4101 14.7356C43.3851 13.2721 42.3551 11.8085 41.3301 10.3499C40.2801 8.85637 39.2351 7.36283 38.1851 5.86929C38.0151 5.62453 37.8301 5.52463 37.5301 5.52463H21.1401C20.4501 5.52463 20.0951 6.34882 20.5051 6.8833C24.5401 12.1981 28.5751 17.5129 32.6101 22.8227C27.4801 25.4702 22.3451 28.1176 17.2101 30.765C11.6251 33.6472 6.03512 36.5294 0.450123 39.4066C-0.309877 39.7962 -0.049877 41 0.810123 41H20.8501C20.9851 41 21.1051 40.97 21.2251 40.9051C24.3701 39.1918 27.5151 37.4785 30.6651 35.7601C33.5001 34.2166 36.3401 32.6681 39.1751 31.1247C40.3851 32.6831 41.6001 34.2366 42.8101 35.7951C44.0801 37.4235 45.3451 39.0569 46.6151 40.6853"
            fill="#D4D5D7"
          />
        </g>
        <path
          d="M8.51 38.5074C17.77 33.5523 27.03 28.5971 36.29 23.6419C32.335 18.432 28.375 13.2221 24.42 8.01217H36.72C39.035 11.309 41.35 14.6057 43.66 17.9075C53.555 12.7675 63.45 7.63254 73.345 2.49255H86.66C73.945 9.35586 61.235 16.2242 48.52 23.0875C52.45 28.2274 56.375 33.3674 60.305 38.5074H47.995C45.26 34.9958 42.53 31.4793 39.795 27.9677C33.345 31.4793 26.9 34.9958 20.45 38.5074H8.505"
          fill="white"
        />
        <path
          d="M85.2 30.6151C85.325 30.1306 85.45 29.6461 85.575 29.1565C86.145 26.9537 84.44 25.4352 82.32 25.4352H73.675C73.73 25.2304 73.78 25.0256 73.835 24.8208H86.695C87.06 23.4072 87.415 22.0235 87.76 20.6848C82.72 20.6898 77.68 20.6998 72.635 20.7048C71.575 20.7048 70.53 21.0994 69.675 21.7488C69.25 22.0735 68.87 22.4681 68.56 22.9126C68.25 23.3572 68.01 23.8617 67.875 24.3912C67.805 24.6709 67.73 24.9507 67.66 25.2304C67.13 27.2634 68.575 29.3414 70.725 29.3414H79.405C79.325 29.6461 79.25 29.9458 79.17 30.2455C74.9 30.2255 70.63 30.2055 66.36 30.1855C65.995 31.6092 65.635 32.9978 65.295 34.3215H80.375C82.5 34.3215 84.66 32.6731 85.195 30.6151"
          fill="#2D2D2D"
        />
        <path
          d="M89.8101 24.4112C90.3151 22.4531 92.2501 20.6998 94.3501 20.7048H109.595C109.405 21.3891 109.215 22.0834 109.02 22.7878C108.82 23.507 108.62 24.2413 108.415 24.9756H95.3751C95.3301 25.1554 95.2801 25.3353 95.2351 25.5151C99.3051 25.5351 103.375 25.5551 107.445 25.575C107.115 26.8488 106.785 28.1275 106.46 29.3963C102.39 29.3763 98.3201 29.3563 94.2501 29.3364C94.1901 29.5711 94.1251 29.8109 94.0651 30.0457C98.4101 30.0357 102.76 30.0307 107.105 30.0207C106.75 31.4993 106.405 32.9428 106.075 34.3165H91.1401C88.9051 34.3165 87.7551 32.3584 88.2851 30.3054C88.7901 28.3523 89.3051 26.3593 89.8051 24.4062"
          fill="#2D2D2D"
        />
        <path
          d="M50.25 33.4174L60.31 38.5074C56.385 33.3674 52.455 28.2274 48.525 23.0875H42.24L50.25 33.4174Z"
          fill="#3D3E3E"
        />
        <path
          d="M73.92 5.53958L73.35 2.49255C63.455 7.63254 53.56 12.7675 43.665 17.9075L42.24 23.0875L73.92 5.53958Z"
          fill="#4A4A4B"
        />
        <path
          d="M86.665 2.49255H73.35L73.92 5.53958L86.665 2.49255Z"
          fill="#434342"
        />
        <path
          d="M42.24 23.0875H48.525C61.24 16.2242 73.95 9.35586 86.665 2.49255L73.92 5.53958L42.24 23.0875Z"
          fill="#111212"
        />
        <path
          d="M42.24 23.0874L43.665 17.9075C41.35 14.6107 39.035 11.3139 36.725 8.01715L34.375 12.8025L42.245 23.0874"
          fill="#444444"
        />
        <path
          d="M20.455 38.5074L20.22 35.6452L8.51501 38.5024C8.51501 38.5024 8.51501 38.5024 8.51001 38.5024H20.455"
          fill="#090B0B"
        />
        <path
          d="M20.455 38.5074C26.905 34.9958 33.35 31.4793 39.8 27.9677L42.24 23.0875L20.225 35.6452L20.46 38.5074"
          fill="#080A0A"
        />
        <path d="M48 38.5075H60.31L50.25 33.4174L48 38.5075Z" fill="#090B0B" />
        <path
          d="M50.25 33.4174L42.2401 23.0875L39.8 27.9677C42.535 31.4793 45.265 34.9958 48 38.5074L50.25 33.4174Z"
          fill="#0A0B0B"
        />
        <path
          d="M42.24 23.0875L36.29 23.6419C27.035 28.5971 17.775 33.5523 8.52002 38.5024L20.225 35.6452L42.24 23.0875Z"
          fill="#4C4B4C"
        />
        <path
          d="M36.72 8.01221H24.42L34.37 12.7975L36.72 8.01221Z"
          fill="#434342"
        />
        <path
          d="M34.37 12.7975L24.42 8.01221C28.375 13.2221 32.335 18.432 36.29 23.637L42.24 23.0825L34.375 12.7975"
          fill="#0D0E0E"
        />
        <path
          d="M129.445 26.1744C129.335 26.594 129.17 26.9936 128.955 27.3633C128.74 27.7329 128.47 28.0726 128.165 28.3673C127.86 28.662 127.515 28.9118 127.145 29.1166C126.96 29.2165 126.77 29.3064 126.575 29.3813C126.475 29.4213 126.375 29.4512 126.275 29.4862C126.175 29.5162 126.075 29.5412 125.97 29.5711C126.755 31.2045 127.525 32.808 128.255 34.3265H121.985C121.48 32.9778 120.955 31.5642 120.415 30.1206C119.88 28.692 119.335 27.2384 118.795 25.7898H123.685C123.765 25.4851 123.84 25.1854 123.92 24.8807H117.35C116.94 26.4741 116.525 28.0876 116.115 29.671C115.705 31.2695 115.3 32.8379 114.915 34.3265H109.075C109.625 32.1836 110.225 29.8708 110.825 27.5381C111.425 25.2104 112.025 22.8926 112.59 20.7048H126.88C129.115 20.7048 130.37 22.6229 129.825 24.7159C129.7 25.2004 129.575 25.6899 129.45 26.1744"
          fill="#2D2D2D"
        />
        <path
          d="M130.95 34.3215H136.81C137.365 32.1786 137.96 29.8659 138.56 27.5332C139.16 25.2054 139.76 22.8877 140.325 20.6998H134.465C133.9 22.8877 133.305 25.2054 132.705 27.5332C132.105 29.8659 131.505 32.1786 130.955 34.3215"
          fill="#2D2D2D"
        />
        <path
          d="M142.305 24.4112C142.81 22.4531 144.745 20.6998 146.845 20.7048H162.09C161.9 21.3891 161.71 22.0834 161.515 22.7878C161.315 23.507 161.115 24.2413 160.91 24.9756H147.87C147.825 25.1554 147.775 25.3353 147.73 25.5151C151.8 25.5351 155.87 25.5551 159.94 25.575C159.61 26.8488 159.28 28.1275 158.955 29.3963C154.885 29.3763 150.815 29.3563 146.745 29.3364C146.685 29.5711 146.625 29.8109 146.56 30.0457C150.905 30.0357 155.255 30.0307 159.6 30.0207C159.245 31.4993 158.9 32.9428 158.57 34.3165H143.635C141.4 34.3165 140.25 32.3584 140.785 30.3054C141.29 28.3523 141.805 26.3593 142.305 24.4062"
          fill="#2D2D2D"
        />
        <path
          d="M181.355 30.6151C181.48 30.1306 181.605 29.6461 181.73 29.1565C182.3 26.9537 180.595 25.4352 178.475 25.4352H169.83C169.885 25.2304 169.935 25.0256 169.99 24.8208H182.85C183.215 23.4072 183.57 22.0235 183.915 20.6848C178.875 20.6898 173.835 20.6998 168.79 20.7048C167.73 20.7048 166.685 21.0994 165.83 21.7488C165.405 22.0735 165.025 22.4681 164.715 22.9126C164.405 23.3572 164.165 23.8617 164.03 24.3912C163.96 24.6709 163.885 24.9507 163.815 25.2304C163.285 27.2634 164.73 29.3414 166.88 29.3414H175.56C175.48 29.6461 175.405 29.9458 175.325 30.2455C171.055 30.2255 166.785 30.2055 162.515 30.1855C162.15 31.6092 161.79 32.9978 161.445 34.3215H176.525C178.65 34.3215 180.81 32.6731 181.345 30.6151"
          fill="#2D2D2D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2419_16404">
          <rect width="189" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default XSeries

import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * CloseBlock icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const CloseBlock = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 24 24" {...remainingProps}>
      <g fillRule="nonzero" fill="none">
        <rect fill={colorString} width="24" height="24" rx="6" />
        <g fill="#FFF" stroke="#FFF">
          <path d="m16.247 16.853-9.1-9.1c-.081-.081-.042-.252.088-.382l.47-.47c.13-.13.3-.169.381-.088l9.1 9.1c.081.082.042.253-.088.383l-.47.47c-.129.129-.3.168-.38.087Z" />
          <path d="m8.086 16.853 9.1-9.1c.082-.081.042-.252-.088-.382l-.47-.47c-.129-.13-.3-.169-.38-.088l-9.101 9.1c-.081.082-.042.253.088.383l.47.47c.13.129.3.168.381.087Z" />
        </g>
      </g>
    </svg>
  )
}

export default CloseBlock

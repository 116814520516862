import React from "react"
import { IconProps } from "./Icon.d"

/**
 * MapPin icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const MapPin = ({ ...remainingProps }: IconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 72 72" {...remainingProps}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M36 0C18.87 0 6.31 18.46 14.636 33.476L36 72l21.364-38.524C65.689 18.46 53.13 0 36 0m0 2.88c7.467 0 14.507 4.094 18.372 10.686 3.51 5.986 3.685 12.734.482 18.51L36 66.074 17.146 32.076c-3.203-5.776-3.028-12.524.482-18.51C21.493 6.974 28.533 2.88 36 2.88"
          fill="#000"
        />
        <path
          d="M37.424 17a.5.5 0 1 0-.001-.999.5.5 0 0 0 .001.999Zm1.994 3a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-2.99 5a.5.5 0 1 1-.002.999.5.5 0 0 1 .001-.999Zm.996-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-2.99 5a.5.5 0 1 1-.002.999.5.5 0 0 1 .001-.999Zm.996-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm6.98 5a.5.5 0 1 1-.002.999.5.5 0 0 1 .001-.999Zm-.998-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-8.973 3a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-1.994 1a.5.5 0 1 0-.001-.999.5.5 0 0 0 .001.999Zm6.98 3a.5.5 0 1 0-.002-.999.5.5 0 0 0 .001.999Zm0-2a.5.5 0 1 0-.002-.999.5.5 0 0 0 .001.999Zm-6.98 1a.5.5 0 1 0-.001-.999.5.5 0 0 0 .001.999Zm-.997 3a.5.5 0 1 0-.001-.999.5.5 0 0 0 .001.999Zm12.961 0a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-.997-1a.5.5 0 1 1-.001.999.5.5 0 0 1 .001-.999Zm-12.96 0a.5.5 0 1 0-.002-.999.5.5 0 0 0 .001.999Zm.996 1a.5.5 0 1 0-.001-.999.5.5 0 0 0 .001.999Zm11.964-2a.5.5 0 1 0-.001-.999.5.5 0 0 0 .001.999Z"
          fill="#C00"
        />
        <path
          d="M30.943 27.667c.006-.055.032-.1.032-.157 0-2.779 2.254-5.04 5.025-5.04 2.771 0 5.025 2.261 5.025 5.04 0 .056.026.102.032.157A7.141 7.141 0 0 1 36 29.76c-1.973 0-3.759-.799-5.057-2.092M36 15.36c3.964 0 7.179 3.225 7.179 7.2 0 .474-.052.935-.14 1.383-1.303-2.578-3.967-4.352-7.039-4.352-3.072 0-5.736 1.774-7.04 4.352a7.187 7.187 0 0 1-.139-1.383c0-3.975 3.215-7.2 7.179-7.2m0-2.88c-5.542 0-10.05 4.524-10.05 10.08 0 5.559 4.508 10.08 10.05 10.08 5.542 0 10.05-4.521 10.05-10.08 0-5.556-4.508-10.08-10.05-10.08"
          fill="#000"
        />
      </g>
    </svg>
  )
}

export default MapPin

import React from "react"
import { CheckMarkInCircleProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Checkmark icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {TWColor|string} props.checkmarkColor - Fill color of the checkmark
 *
 * @returns <svg />
 */
const CheckMarkInCircle: React.FC<CheckMarkInCircleProps> = ({
  color,
  checkmarkColor = "transparent",
  ...remainingProps
}) => {
  const colorString = processIconColor(color)
  const checkmarkColorString = processIconColor(checkmarkColor)
  return (
    <svg viewBox="0 0 27 27" {...remainingProps}>
      <circle cx="13.5" cy="13.5" r="10" fill={checkmarkColorString} />
      <path
        d="M13.4144 0.514138C10.0246 0.585917 6.79942 1.98984 4.43729 4.42201C2.07509 6.85423 0.765817 10.119 0.792892 13.5094C0.778097 15.7824 1.36417 18.0191 2.49209 19.9924C3.62002 21.966 5.24966 23.6062 7.21559 24.747C9.18172 25.8878 11.4145 26.4887 13.6873 26.4887C15.9605 26.4887 18.1933 25.8878 20.1591 24.747C22.1252 23.606 23.7549 21.9659 24.8826 19.9924C26.0105 18.019 26.5968 15.7823 26.5818 13.5094C26.6003 11.2034 26.0001 8.93439 24.8436 6.93939C23.6871 4.94428 22.0167 3.29574 20.0065 2.16564C17.9963 1.03565 15.7198 0.465238 13.4143 0.514026L13.4144 0.514138ZM20.8709 7.78839C21.2193 7.77301 21.559 7.89942 21.8127 8.13863C22.0663 8.37784 22.2124 8.70949 22.2175 9.05813C22.2227 9.40677 22.0864 9.74266 21.8399 9.98933L12.4904 19.3377H12.4906C12.168 19.6601 11.7306 19.8412 11.2746 19.8412C10.8186 19.8412 10.3812 19.6601 10.0588 19.3377L5.50401 14.7841C5.17985 14.4577 5.05386 13.9835 5.1734 13.5394C5.29293 13.0953 5.63994 12.7484 6.08409 12.6289C6.52824 12.5094 7.00239 12.6352 7.32864 12.9593L11.2744 16.9059L20.0149 8.16535V8.1655C20.243 7.93728 20.5483 7.8028 20.8705 7.78843L20.8709 7.78839Z"
        fill={colorString}
      />
    </svg>
  )
}

export default CheckMarkInCircle

import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Expand icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Expand: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 30 29" {...remainingProps}>
      <g fill={colorString} fillRule="nonzero">
        <path d="m3.835 10.27 1.324.014-.041-3.649 7.694 7.615.935-.925L6.053 5.71l3.686.04-.014-1.31-6.05-.066.16 5.896zM25.86 10.27l-1.324.014.04-3.649-7.693 7.615-.936-.926 7.695-7.614-3.687.04.014-1.31 6.051-.066-.16 5.896zM3.834 19.71l1.325-.014-.041 3.648 7.694-7.613.935.925-7.694 7.614 3.686-.04-.014 1.31-6.05.066.16-5.896zM25.86 19.71l-1.324-.015.04 3.65-7.693-7.615-.935.926 7.694 7.613-3.687-.04.014 1.31 6.05.066-.159-5.894z" />
      </g>
    </svg>
  )
}

export default Expand

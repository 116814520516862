import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Learn icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Learn = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 50 50" {...remainingProps}>
      <path
        d="M25 1C11.7486 1 1 11.7486 1 25C1 38.2514 11.7486 49 25 49C38.2514 49 49 38.2514 49 25C48.9829 11.7486 38.2514 1.01714 25 1ZM25 47.2857C12.6914 47.2857 2.71429 37.3086 2.71429 25C2.71429 12.6914 12.6914 2.71429 25 2.71429C37.3086 2.71429 47.2857 12.6914 47.2857 25C47.2686 37.3 37.3 47.2686 25 47.2857Z"
        stroke={colorString}
        strokeWidth="1.5"
        fill={colorString}
      />
      <path
        d="M13.4287 20.7314C11.0716 20.7314 9.16016 22.6429 9.16016 25C9.16016 27.3572 11.0716 29.2686 13.4287 29.2686C15.7859 29.2686 17.6973 27.3572 17.6973 25C17.6973 22.6429 15.7859 20.7314 13.4287 20.7314ZM13.4287 27.5543C12.023 27.5543 10.8744 26.4143 10.8744 25C10.8744 23.5943 12.0144 22.4457 13.4287 22.4457C14.8344 22.4457 15.983 23.5857 15.983 25C15.983 26.4057 14.843 27.5543 13.4287 27.5543Z"
        stroke={colorString}
        strokeWidth="1.5"
        fill={colorString}
      />
      <path
        d="M24.999 20.7314C22.6419 20.7314 20.7305 22.6429 20.7305 25C20.7305 27.3572 22.6419 29.2686 24.999 29.2686C27.3562 29.2686 29.2676 27.3572 29.2676 25C29.2676 22.6429 27.3562 20.7314 24.999 20.7314ZM24.999 27.5543C23.5933 27.5543 22.4448 26.4143 22.4448 25C22.4448 23.5943 23.5848 22.4457 24.999 22.4457C26.4048 22.4457 27.5533 23.5857 27.5533 25C27.5533 26.4057 26.4133 27.5543 24.999 27.5543Z"
        stroke={colorString}
        strokeWidth="1.5"
        fill={colorString}
      />
      <path
        d="M36.5713 20.7314C34.2142 20.7314 32.3027 22.6429 32.3027 25C32.3027 27.3572 34.2142 29.2686 36.5713 29.2686C38.9284 29.2686 40.8399 27.3572 40.8399 25C40.8399 22.6429 38.9284 20.7314 36.5713 20.7314ZM36.5713 27.5543C35.1656 27.5543 34.017 26.4143 34.017 25C34.017 23.5943 35.157 22.4457 36.5713 22.4457C37.977 22.4457 39.1256 23.5857 39.1256 25C39.1256 26.4057 37.9856 27.5543 36.5713 27.5543Z"
        stroke={colorString}
        strokeWidth="1.5"
        fill={colorString}
      />
    </svg>
  )
}

export default Learn

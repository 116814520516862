import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * X icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const X: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 19 19" {...remainingProps}>
      <g fill={colorString} fillRule="evenodd">
        <path d="M16.705 18.402.265 1.962c-.146-.146-.075-.455.16-.69l.848-.848c.234-.234.543-.305.69-.159l16.44 16.44c.146.147.074.456-.16.69l-.848.849c-.234.234-.543.305-.69.158Z" />
        <path d="m1.962 18.402 16.44-16.44c.147-.146.076-.455-.159-.69l-.848-.848c-.234-.234-.543-.305-.69-.159L.265 16.705c-.146.147-.075.456.16.69l.848.849c.234.234.543.305.69.158Z" />
      </g>
    </svg>
  )
}

export default X

import React, { useRef, useEffect, useState } from "react"
import { ModalProps } from "./Modal.d"
import tw from "twin.macro"
import Close from "./Close"
import Backdrop from "./Backdrop"

/**
 * Basic modal
 *
 * @param {boolean} props.open - Modal open or close state
 * @param {boolean} props.closeStyled - Style the close button with a red background and white X
 * @param {ReactNode} props.children - Content inside the modal
 * @param {function} props.onClose - Close button onClick function
 *
 * @returns {JSX.Element} <div></div>
 */

const Modal: React.FC<ModalProps> = ({
  open = false,
  children,
  closeStyled = false,
  backdropStyled = false,
  fromDealersNearYou = false,
  onClose,
  analyticsId,
  zIndex,
  preventDefaultClose,
  hideCancelText,
  ...remainingProps
}) => {
  const [modalShow, setModalShow] = useState(false)
  const modalRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (open) {
      modalRef?.current?.focus()
    }

    const timer = setTimeout(() => setModalShow(open), 300)
    return () => clearTimeout(timer)
  }, [open])

  const handleKeyDown = (e: any) => {
    const closeBtn = document.getElementById("modalCloseBtn")
    if (e.key === "Tab" && document.activeElement === closeBtn) {
      modalRef.current?.focus()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  const handleModalHide = () => {
    !preventDefaultClose && setModalShow(false)
    setTimeout(onClose, 300)
  }
  useEffect(() => {
    if (open && closeStyled) {
      modalRef?.current?.focus()
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [open])

  return (
    <>
      {open && (
        <Backdrop
          open={modalShow}
          onClose={handleModalHide}
          backdropStyled={backdropStyled}
          fromDealersNearYou={fromDealersNearYou}
          zIndex={zIndex}
        >
          <dialog
            ref={modalRef}
            tabIndex={-1}
            aria-label={"dialog box"}
            role="dialog"
            aria-labelledby="title"
            aria-describedby="description"
            css={[
              tw`transition-all opacity-0 block rounded-lg absolute z-50 bg-white transform translate-y-8 p-0`,
              modalShow && tw`opacity-100 translate-y-0`,
              !backdropStyled && tw`shadow-5`,
            ]}
            {...remainingProps}
          >
            {children}
            <Close
              open={modalShow}
              closeStyled={closeStyled}
              onClose={handleModalHide}
              analytics-id={analyticsId || null}
              hideCancelText={hideCancelText}
              css={tw`z-[9999]`}
            />
          </dialog>
        </Backdrop>
      )}
    </>
  )
}

export default Modal

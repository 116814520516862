import React, { useContext, useEffect, useRef, useState } from "react"
import { FindProps } from "./Find.d"
import { Input } from "../../atoms/Input"
import { Button } from "../../atoms/Button"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import { setZip } from "../../../contexts/Location/actions"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { navigate } from "gatsby"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"

/**
 *
 * @author Tyler
 * @summary - The location lookup component in the header menu
 *
 * @component
 * @param {string} type - Will return findMyDealer or scheduleService for conditional rendering
 * @returns <Find />
 */

const Find: React.FC<FindProps> = ({
  type = "findMyDealer",
  scheduleService = false,
  tact = false,
  analyticsId = null,
  ...remainingProps
}) => {
  const { _, language } = useContext(LanguageContext)
  const [{ zip, fetchingLocationData, dealers }, dispatch] =
    useContext(LocationContext)
  const [inputValue, setInputValue] = useState("")

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { setRefineInAction } = useTealiumContext()
  const dealerCodes = dealers
    ?.filter(dealer => dealer.TactUrl)
    .map(dealer => dealer.DealerCode)
  const dealerNames = dealers
    ?.filter(dealer => dealer.TactUrl)
    .map(dealer => dealer.Name)
  const pageload = useRef(true)

  useEffect(() => {
    if (!pageload.current) {
      if (type == "tact") {
        setRefineInAction({ inProgess: true })
        trackTealEvent({
          tealium_event: "zipcode_go",
          customer_zip: inputValue,
          dealer_name: dealerNames,
          dealer_code: dealerCodes,
          search_results: dealerNames?.length,
        })
      }
    }
    pageload.current = true
  }, [dealers])

  const handleSubmit = () => {
    dispatch(setZip(Number(inputValue)))
    LocalStorageClient.write("confirmedZip", true)
    LocalStorageClient.delete("sourceZip")

    // tealium
    setRefineInAction({ inProgess: true })
    if (type == "scheduleService") {
      trackTealEvent({ customer_zip: inputValue })
      let path = "/services/#dealersNearYou"
      if (language === "es") {
        path = `/es${path}`
      }
      navigate(path)
    } else if (type != "tact") {
      trackTealEvent({ tealium_event: "zipcode_go", customer_zip: inputValue })
    }
    pageload.current = false
  }

  useEffect(() => {
    if (!zip) return
    setInputValue(zip.toString())
  }, [zip])

  const heading =
    type === "findMyDealer"
      ? _("Find My Dealer")
      : type === "scheduleService"
        ? _("Schedule Service")
        : type === "tact"
          ? _("Find A Dealer To Apply Now")
          : ""

  return (
    <>
      <article css={[tw`w-full pl-12`]}>
        <div
          css={[tw`bg-white px-8 py-4 my-6 self-start rounded-lg w-full`]}
          {...remainingProps}
        >
          <h3 css={[tw`font-semibold uppercase`]}>{heading}</h3>
          <span css={[tw`text-sm`, scheduleService && tw`normal-case`]}>
            {_("Enter Zip")}
          </span>
          <div css={[tw`relative mt-6 w-full`]}>
            <Input
              name="zip"
              type="number"
              css={[tw`text-lg font-semibold`]}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
            <Button
              primary
              css={[
                tw`absolute right-0 bottom-0 p-0 !pl-0 !pr-0 pb-[2px] mb-[0.5rem] h-6 w-20 text-xs font-light uppercase`,
              ]}
              onClick={handleSubmit}
              analytics-id={analyticsId || null}
            >
              {_("Find")} <span css={[tw`sr-only`]}>{_("Dealers")}</span>
              {/* <Loader /> */}
            </Button>
          </div>
          {type === "findMyDealer" && (
            <div css={[tw`flex items-center text-xs mt-6`]}>
              <Icon.Location color="red-400" css={[tw`h-8 mr-2`]} />
              <span>{_("Use My Location")}</span>
            </div>
          )}
        </div>
      </article>
    </>
  )
}

export default Find

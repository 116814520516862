import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Filter icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */
const Filter: React.FC<IconProps> = ({ color, filled, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 72 72" {...remainingProps}>
      <path
        stroke={colorString}
        strokeWidth="4"
        d="M72 4H0l28.8 34.161v23.617L43.2 69V38.161z"
        fill={filled ? colorString : "none"}
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Filter

import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Upload icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Upload = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 -2 72 72" {...remainingProps}>
      <g
        stroke={colorString}
        strokeWidth="5"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path d="m6.156 46.34 30.02-33.843L66.103 46.34M36.178 14.517V72M5.484.496h61.032" />
      </g>
    </svg>
  )
}

export default Upload

import React from "react"
import { InfoOutlinedIconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Info icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const InfoOutlined = ({
  color,
  filled,
  ...remainingProps
}: InfoOutlinedIconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 8 8" {...remainingProps}>
      <g fill="none" fillRule="evenodd">
        <path
          d="m4,.25c2.07,0,3.75,1.68,3.75,3.75s-1.68,3.75-3.75,3.75S.25,6.07.25,4,1.93.25,4,.25Z"
          fill="#FFF"
          stroke={colorString}
          strokeWidth="0.5"
        />
        <path
          d="m3.65,6h.68v-2.72h-.68v2.72Zm.05-3.46c-.08-.08-.12-.18-.12-.29s.04-.21.12-.29c.08-.08.18-.12.3-.12s.21.04.29.12c.08.08.12.18.12.29s-.04.21-.12.29c-.08.08-.18.12-.29.12s-.22-.04-.3-.12Z"
          stroke={colorString}
          strokeWidth="0"
          fill={colorString}
        />
      </g>
    </svg>
  )
}

export default InfoOutlined

import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Language icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Language = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 72 72" {...remainingProps}>
      <path
        d="M68.908 52.587c0 1.501-1.275 2.724-2.841 2.724h-6.061v7.346l-6.61-6.464-.901-.882H33.426c-1.567 0-2.842-1.223-2.842-2.724V35.173c0-1.503 1.275-2.724 2.842-2.724h32.641c1.566 0 2.84 1.221 2.84 2.724v17.414ZM30.403 21.159c3.815-.111 7.531-.618 10.952-1.52.628 2.69 1.004 5.604 1.102 8.639H30.403v-7.12Zm0-16.98c4.267.939 7.996 5.803 10.149 12.574-3.159.828-6.6 1.298-10.15 1.406V4.179Zm18.44 9.192a30.154 30.154 0 0 1-5.405 2.493c-1.323-4.136-3.218-7.638-5.498-10.208a25.975 25.975 0 0 1 10.903 7.715Zm1.773 2.436a25.52 25.52 0 0 1 4.087 12.47h-9.235c-.1-3.352-.515-6.559-1.221-9.51a33.134 33.134 0 0 0 6.37-2.96ZM27.39 18.159c-3.548-.108-6.991-.578-10.148-1.406C19.394 9.983 23.123 5.118 27.39 4.18v13.98Zm0 10.119H15.336c.097-3.035.475-5.95 1.103-8.639 3.42.902 7.136 1.409 10.951 1.52v7.119Zm0 8.679c-3.922.115-7.732.654-11.233 1.602a44.978 44.978 0 0 1-.82-7.281H27.39v5.679Zm0 18.418c-4.54-.999-8.478-6.436-10.546-13.893 3.268-.895 6.846-1.411 10.546-1.525v15.418ZM8.201 45.192c1.707-1.092 3.656-2.027 5.781-2.798 1.326 4.695 3.359 8.672 5.871 11.504a25.944 25.944 0 0 1-11.652-8.706ZM3.09 31.278h9.236c.086 2.859.409 5.602.942 8.18-2.481.874-4.75 1.96-6.735 3.235A25.512 25.512 0 0 1 3.09 31.278Zm4.087-15.471a33.098 33.098 0 0 0 6.37 2.958c-.706 2.953-1.12 6.16-1.22 9.513H3.088a25.535 25.535 0 0 1 4.087-12.471ZM19.853 5.656c-2.28 2.57-4.175 6.072-5.497 10.208A30.154 30.154 0 0 1 8.95 13.37a25.975 25.975 0 0 1 10.903-7.715Zm46.214 23.712h-8.313l-.037-1.09C56.953 13.587 45.155 1.836 30.403 1.077V1H27.39v.077C12.64 1.836.84 13.587.077 28.277H0v3h.077C.84 45.97 12.639 57.719 27.39 58.478l1.505-.085.002.162h.001l6.545-.162h15.786L63.1 70.001V58.392h2.968c3.278 0 5.935-2.6 5.935-5.805V35.173c0-3.207-2.657-5.805-5.935-5.805Z"
        stroke={colorString}
        strokeWidth=".5"
        fill={colorString}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Language

import React from "react"
import { processIconColor } from "../../../helpers"
import { IconProps } from "./Icon.d"

/**
 * MapPin icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns {JSX.Element} <svg />
 */
const MapPinWithCircle = ({
  color,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg
      width="79"
      height="95"
      viewBox="0 0 79 95"
      fill="none"
      {...remainingProps}
    >
      <path
        d="M76 39.4091C76 67.7273 39.5 92 39.5 92C39.5 92 3 67.7273 3 39.4091C3 29.7528 6.84552 20.492 13.6906 13.664C20.5357 6.83595 29.8196 3 39.5 3C49.1804 3 58.4643 6.83595 65.3094 13.664C72.1545 20.492 76 29.7528 76 39.4091Z"
        stroke={colorString}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5 51.5455C46.2195 51.5455 51.6667 46.1118 51.6667 39.4091C51.6667 32.7064 46.2195 27.2727 39.5 27.2727C32.7805 27.2727 27.3333 32.7064 27.3333 39.4091C27.3333 46.1118 32.7805 51.5455 39.5 51.5455Z"
        stroke={colorString}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MapPinWithCircle
